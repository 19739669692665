import React from 'react'
import { useState } from 'react';
import { useEffect } from 'react';

function RazorpayWidget({ razorpayKey, amount }) {

    useEffect(() => {
        try {
            const widgetConfig = {
                "key": razorpayKey,
                "amount": amount*100,
            };
            const rzpAffordabilitySuite = new window.RazorpayAffordabilitySuite(widgetConfig);
            rzpAffordabilitySuite.render();

        } catch (err) {
            console.error(err);
        }

    }, [amount])


    return (
        <div className='tw-py-5' id="razorpay-affordability-widget"> </div>
    )
}

export default RazorpayWidget