import React from 'react'

export default function HappyCustomer() {
    return (
        <div className="tw-flex tw-flex-col tw-gap-5 tw-items-center tw-justify-center tw-py-10 tw-px-5 tw-bg-gray-100">
            <h2 className="tw-px-[4rem] tw-text-[25px] xl:tw-text-[30px] md:tw-text-auto lg:tw-text-auto xl:tw-text-auto py-3 tw-bg-[#982876] tw-rounded-full tw-text-white tw-animate-bounce tw-text-center">8,00,000+ Happy Customers</h2>
            <p className="text-center tw-text-[16px] sm:tw-text-[18px] lg:tw-text-[20px] xl:tw-text-[20px] tw-capitalize">People's Trusted Mattress Brand since 2012 </p>
        </div>
    )
}
