import "./styles/ProductDetail.css";

import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { CartContext } from "../Context/Ecomcontext";
import Loader from "../Components/loader";
import Related_product from "../Components/related_products";
import axios from "axios";
import cartSection from "../Helper/Cart";
import { toast } from "react-toastify";
import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";
import { addtoWishlist } from "../Helper/Wishlist";
import Lottie from "react-lottie";
import CoupenPng from "./MobileComponents/images/badge.png";
import * as animationData from "./gift.json";
import { ScrolltoTop } from "../utility";
import { CommonContext } from "../Context/CommonContext";
import RazorpayWidget from "./Components/RazorpayWidget";
import { ScriptAdding } from "../data/utility";

const imgurl = process.env.REACT_APP_IMG_URL;
const baseurl = process.env.REACT_APP_BASE_URL;

const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
        preserveAspectRatio: "xMidYMid slice",
    },
};

const userData = JSON.parse(localStorage.getItem("userInfo"));

export default function ProductDetailNew() {
    const [open, setOpen] = useState(false);
    const [pickedColorImg, setPickedColorImg] = useState("");
    const [pickedColorId, setPicketColorId] = useState("");
    const [pickedColor, setPickedColor] = useState("Stock Color");
    const [discount_less, setDiscountLess] = useState(0);

    //REVIEW-CUSTOM
    const initialState = {
        name: '',
        email: '',
        comments: '',
    };
    const [reviewForm, setreviewForm] = useState(initialState);

    const initialState_image = {
        image_1: '',
        product_alt_1:'',
        image_2: '',
        product_alt_2:'',
        image_3: '',
        product_alt_3:'',
        image_4: '',
        product_alt_4:'',
    };
    const [imagestateandProductalt, setimagestateandProductalt] = useState(initialState_image);


    const handleReviewForm = (e) => {
        const { name, value } = e.target;
        setreviewForm({
            ...reviewForm,
            [name]: value,
        });
    };

    const handleSubmitReview = () => {

        if (reviewForm.name === "" || reviewForm.email === "" || reviewForm.comments === "") {
            toast.error("Please Fill All the Fields");
        } else {
            const requestBody = {
                ...reviewForm,
                product_id: id,
            };
            const result_data = axios
                .post(baseurl + "user/product_review/", requestBody)
                .then(customer_review_list_func());
            toast.success("Thanks For Your Review!");
            document.getElementById("review-form").reset();
            setreviewForm(initialState)
        }

    };

    const onOpenModal = async (data) => {
        setOpen(true);
        setPickedColorImg(data.img);
        setPickedColor(data.colour_name);
        setPicketColorId(data.id);
    };
    const onCloseModal = () => setOpen(false);

    //Image variables
    let navigate = useNavigate();

    toast.configure();

    //Customer Review

    const [loader, setloader] = useState(false);

    const [coupenstatus, setcoupenstatus] = useState(false);
    const [coloursOptions, setColoursOptions] = useState([]);
    //End Customer

    //Function For Insert Comments

    const [id, setId] = useState(null);
    const [cate_name, setcategory_name] = useState("");
    const [cart, setCart] = useContext(CartContext);
    const [price, set_price] = useState(0);
    const [coupen_code, set_coupen] = useState(0);
    const [bed_Data, set_bed] = useState({});
    const [dimension, set_dimention] = useState({});
    const [thickness, set_thickness] = useState({});
    const [Product_Details, set_product_Details] = useState({});
    const [subdivision, set_subdivision] = useState({});
    const [customer_review_list, set_cus_list] = useState({});
    // INTIAL STATES
    const [type_sub_devision, set_type_sub] = useState("");
    const [height, set_height] = useState("");
    const [local, set_localbed] = useState("");
    const [Dimen, set_localdimen] = useState("");
    const [cato, set_cat] = useState(1);

    const [new_dbcoupen, set_dbcoupen] = useState("");
    const [new_coupen, set_newcoupen] = useState(0);
    const [coupenDisPer, set_coupenDisPer] = useState(0);
    //Image Section

    const [free_image, setfreeimage] = useState("pr10.jpg");
    const [bot_img, setbot_img] = useState("pr10.jpg");
    const [top_img, settop_img] = useState("pr10.jpg");
    const [description_image, setdescriptionimage] = useState("pr10.jpg");
    // const location = useLocation();
    const { product_name_param } = useParams();
    const [discount, set_discount_percentage] = useState(0);


    const { cartModal, setCartModal } = useContext(CommonContext);

    //CUSTOMISE HEIGHT AND WIDTH

    const [custom_height, set_custom_height] = useState("");
    const [custom_width, set_custom_width] = useState("");

    useEffect(() => {
        dimentionMethod();
    }, [height, local]);

    useEffect(() => {
        getProduct_price();
    }, [Dimen]);

    useEffect(() => {
        getSizedandHeight();
    }, [type_sub_devision]);

    useEffect(() => {
        ScrolltoTop();
        getProduct_price();
        couoncode_get();
    }, []);
    useEffect(() => {
        ScriptAdding('https://cdn.razorpay.com/widgets/affordability/affordability.js')
        set_dimention({})
        product_id_get(getProductById);
    }, [id, cato]);

    const product_id_get = async (callback) => {
        const requestBody = {
            product_name: product_name_param.replace(/-/g, " "),
        };

        const result_data = await axios

            .post(baseurl + "user/product_id_find/", requestBody)

            .then(function (response) {
                setId(response.data.data[0].id);
                customer_review_list_func(response.data.data[0].id);
                setcategory_name(response.data.data[0].category_name);
                callback();
            });
    };



    const customer_review_list_func = async (product_id) => {

        const pr_id = id ? id : product_id
        const requestBody = {
            product_id: pr_id,
        };
        const result_data = await axios
            .post(baseurl + "user/product_review_list/", requestBody)
            .then(function (response) {

                set_cus_list(response.data.data);
            });
    };
    const couoncode_get = async () => {
        const requestBody = {
            c_for: "Mattress",
        };

        const result_data = await axios

            .post(baseurl + "user/coupon_get/", requestBody)

            .then(function (response) {
                //TO SET PRODUCT BED TYPE

                set_dbcoupen(response.data.data[0].coupen_code);
                set_coupenDisPer(response.data.data[0].discount_percentage);
            });
    };
    const copenApply = () => {
        if (new_dbcoupen == new_coupen) {
            toast.success("Coupon Applied");
            setcoupenstatus(true);
            set_discount_percentage(coupenDisPer);

            // const discount_less = percentageCalculation(price, coupenDisPer);
            DiscountCalculations(price, coupenDisPer);

            // set_coupenDis(discount_less);
        } else {
            toast.error("Invalide Coupon Code");
            setcoupenstatus(false);
        }
    };
    const getProductById = async () => {
        const requestBody = {
            product_id: id,
            name: product_name_param.replace(/-/g, " "),
            type_devision: type_sub_devision,
            height: height,
            bed_type: local,
            dimension: Dimen,
        };

        const result_data = await axios
            .post(baseurl + "user/product_details/", requestBody)

            .then(function (response) {
                response && setColoursOptions(response.data.colours);
                //TO SET PRODUCT BED TYPE
                set_bed(response.data.bed_type);
                set_localbed(response.data.bed_type[0].bed_type);

                //TO SET PRODUCT_DIMENSION BED TYPE
                if(dimension.length === 0){
                    set_dimention(response.data.dimentions);
                }

                set_localdimen(response.data.dimentions[0].product_dimensions);
                set_discount_percentage(
                    response.data.product_data[0].discount_percentage
                );
                // set_localdimen(response.data.dimentions[0].product_dimensions)

                //TO SET THICKNESS BED TYPE

                set_thickness(response.data.thickness);

                set_height(response.data.thickness[0].thickness);

                //TO SET PRODUCT DETAILS

                set_product_Details(response.data.product_data[0]);

                set_cat(response.data.product_data[0].category_id);

                setimagestateandProductalt({
                    ...imagestateandProductalt,
                    image_1:response.data.product_data[0].product_imageurl,
                    product_alt_1: response.data.product_data[0].product_imageurl_alt,
                    image_2:response.data.product_data[0].product_imageurl_1,
                    product_alt_2: response.data.product_data[0].product_imageurl_1_alt,
                    image_3:response.data.product_data[0].product_imageurl_2,
                    product_alt_3: response.data.product_data[0].product_imageurl_2_alt,
                    image_4:response.data.product_data[0].product_imageurl_3,
                    product_alt_4: response.data.product_data[0].product_imageurl_3_alt,
                });

                settop_img(response.data.product_data[0].description_top_image);
                setbot_img(response.data.product_data[0].description_bottom_image);

                response &&
                    setdescriptionimage(
                        response.data.product_data[0].description_main_image
                    );
                //TO SET PRODUCT DETAILS

                response && set_subdivision(response.data.product_sub);

                response && set_type_sub(response.data.product_sub[0].subdivision);

                response &&
                    set_coupen(response.data.coupen_code[0].discount_percentage);
            });
    };
    //TODO SECOND FUNCTION
    const getSizedandHeight = async () => {

        const requestBody = {
            product_id: id,
            type_devision: type_sub_devision,
        };

        const result_data = await axios

            .post(baseurl + "user/product_height_size/", requestBody)

            .then(function (response) {
                set_thickness(response.data.thickness);

                set_height(response.data.thickness[0].thickness);

                //
                dimentionMethod();
            });
    };
    //TODO THIRD FUNCTION
    const dimentionMethod = async (index, height_get) => {
        const requestBody = {
            product_id: id,
            type_devision: type_sub_devision,
            height: height,
            size: local,
        };

        // console.log(id)
        const result_data = await axios
            .post(baseurl + "user/product_dimension/", requestBody)
            .then(function (response) {
                //TO SET PRODUCT_DIMENSION BED TYPE
                response && set_dimention(response.data.dimentions);
                response && set_localdimen(response.data.dimentions[0].product_dimensions);
                getProduct_price();
            });
    };
    const getProduct_price = async () => {
        setloader(true)

        // alert(id);
        const requestBody = {
            product_id: id,
            bed_type: local,
            dimension: Dimen,
            thickness: height,
            type: type_sub_devision,
            customise_type: 0,
        };

        const result_data = await axios
            .post(baseurl + "user/price_data/", requestBody)
            .then(function (response) {
                response && set_price(response.data.data[0].product_price);
                DiscountCalculations(response.data.data[0].product_price, discount);
                setloader(false)
            });
    };

    var desc = Product_Details.product_description;
    //Free Section
    var free_content = Product_Details.free_content;

    //Description
    var bottom_content = Product_Details.description_bottom;
    var top_content = Product_Details.description_top;
    var top_header = Product_Details.description_top_header;
    var bottom_header = Product_Details.description_bottom_header;
    //Type Subdivision click
    const [heightmenu, setHegihtmenu] = useState(0);
    //Height Button
    const [heightmenu2, setHegihtmenu2] = useState(0);
    //Size Button
    const [heightmenu3, setHegihtmenu3] = useState(0);
    const toggleButton = (index, type_sub_get) => {
        setHegihtmenu(index);

        set_type_sub(type_sub_get);
    };

    const buy_func = () => {
        navigate('/checkout');
    };

    const toggleButton2 = (index, height_get) => {
        setHegihtmenu2(index);
        set_height(height_get);
    };

    const toggleButton3 = (index, size_get) => {
        setHegihtmenu3(index);
        set_localbed(size_get);
        getProduct_price();
    };

    const diment_click = (di) => {
        set_custom_height("");
        set_custom_width("");
        document.getElementById("create-course-form").reset();
        set_localdimen(di);
    };

    const toggleCustomsize = () => {
        var element = document.getElementById("custom-size");
        if (element.hasAttribute("hidden")) {
            element.removeAttribute("hidden");
        } else {
            element.setAttribute("hidden", true);
        }
    };

    const toggleCouponButton = () => {
        var element = document.getElementById("coupon-section1");

        if (element.hasAttribute("hidden")) {
            element.removeAttribute("hidden");
        } else {
            element.setAttribute("hidden", true);
        }
    };

    const toggleCouponButtonMobile = () => {
        var element = document.getElementById("coupon-section");

        if (element.hasAttribute("hidden")) {
            element.removeAttribute("hidden");
        } else {
            element.setAttribute("hidden", true);
        }
    };

    const addTocart = (
        id,
        amt,
        title,
        image,
        bed_type,
        dimension,
        thickness,
        freeSection,
        color,
        dis,
        pri
    ) => {
        var dim =
            custom_height === "" || custom_width === ""
                ? dimension
                : custom_height + " x " + custom_width;
        var bdtype =
            custom_height === "" || custom_width === "" ? bed_type : "Custom";

        // alert(freeSection)
        // return 0;
        //TODO METHOD ACTION FOR ADD
        // toast.success("Product Added Successfully ");

        setCart(
            cartSection.addCart(
                id,
                parseInt(amt),
                title,
                image,
                bdtype,
                dim,
                thickness,
                freeSection,
                color,
                dis,
                pri,
                type_sub_devision
            )
        );
        setCartModal(true);
    };

    //TO CALL CUTOMISE HEIGHT AND WIDTH
    const customise_pricelist = async () => {
        const requestBody = {
            product_id: id,
            bed_type: local,
            customise_hight: custom_height,
            customise_width: custom_width,
            thickness: height,
            type: type_sub_devision,
            customise_type: "1",
        };

        const result_data = await axios
            .post(baseurl + "user/price_customise_data/", requestBody)
            .then(function (response) {
                set_price(response.data.data[0].product_price);
                DiscountCalculations(response.data.data[0].product_price, discount);
            });
    };

    if (
        custom_height !== "" &&
        custom_width !== "" &&
        custom_height.length === 2 &&
        custom_width.length === 2
    ) {
        customise_pricelist();
    }

    const wishlist_add = async (
        p_id,
        total_amount,
        product_name,
        product_image,
        product_size,
        product_dimen,
        product_height
    ) => {
        await addtoWishlist(
            p_id,
            total_amount,
            product_name,
            product_image,
            product_size,
            product_dimen,
            product_height
        );
    };

    const DiscountCalculations = (pri, perc) => {
        var percent = (perc / 100) * pri;
        var lessedAmt = Math.round(percent);
        setDiscountLess(lessedAmt);
    };

    const related = () => {
        return <Related_product category={cate_name} />;
    };

    return (
        <>
            <div className="product-contain tw-overflow-hidden">
                <div id="product-detail">
                    <div className="main-content">
                        <div id="wrapper-site">
                            <div id="content-wrapper">
                                <div id="main">
                                    <div className="page-home">
                                        <nav className="breadcrumb-bg">
                                            <div className="container no-index">
                                                <div className="breadcrumb"></div>
                                            </div>
                                        </nav>
                                        <div className="container">
                                            <div className="content">
                                                <div className="row">
                                                    <div className="col-sm-12 col-lg-12 col-md-12">
                                                        <div className="main-product-detail">

                                                            <div className="product-single row">                                                              
                                                                <ProductLeftSide productImage_data={imagestateandProductalt} freecontent ={free_content} />

                                                                <div className="product-info col-xs-12 col-md-7 col-sm-7">
                                                                    <div className="detail-description">
                                                                        <div className="price-del">

                                                                        </div>


                                                                        <div className="has-borders mobile-responisve">
                                                                            <h1 className=" product-title-style">
                                                                                {Product_Details.product_name}{" "}
                                                                            </h1>
                                                                            <p
                                                                                className="product-dcp"
                                                                                style={{ color: "#982876" }}
                                                                            >
                                                                                * Price of{" "}
                                                                                {custom_height === "" ||
                                                                                    custom_width === ""
                                                                                    ? local + " " + Dimen + " X " + height
                                                                                    : "Custom" +
                                                                                    " " +
                                                                                    custom_height +
                                                                                    " x " +
                                                                                    custom_width +
                                                                                    " X " +
                                                                                    height}
                                                                            </p>
                                                                        </div>

                                                                        <div className="has-borders desktop-responisve">
                                                                            <h1 className="product-title-style">
                                                                                {Product_Details.product_name}{" "}
                                                                            </h1>
                                                                            <p className="product-dcp">
                                                                                * Price of{" "}
                                                                                {custom_height === "" ||
                                                                                    custom_width === ""
                                                                                    ? local + " " + Dimen + " X " + height
                                                                                    : "Custom" +
                                                                                    " " +
                                                                                    custom_height +
                                                                                    " x " +
                                                                                    custom_width +
                                                                                    " X " +
                                                                                    height}
                                                                            </p>
                                                                        </div>

                                                                        <div className="product-price-container desktop-responisve">
                                                                            <div>
                                                                                <div className="price-info">
                                                                                    <div
                                                                                        className="mrp-price"
                                                                                        style={{ marginTop: 0 }}
                                                                                    >
                                                                                        <p className="mrp-rate">
                                                                                            M.R.P
                                                                                            <s style={{ marginLeft: 8 }}>
                                                                                                <i
                                                                                                    className="fa fa-inr"
                                                                                                    aria-hidden="true"
                                                                                                >
                                                                                                    {" "}
                                                                                                </i>{" "}
                                                                                                {price}
                                                                                            </s>
                                                                                        </p>
                                                                                        <p className="offer-percentage">
                                                                                            {discount}% off
                                                                                        </p>
                                                                                    </div>
                                                                                    <div className="bg-btn">
                                                                                        <a href="#">
                                                                                            <span>You Save</span>{" "}
                                                                                            <i
                                                                                                className="fa fa-inr"
                                                                                                aria-hidden="true"
                                                                                            ></i>{" "}
                                                                                            {discount_less}
                                                                                        </a>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div
                                                                                className="current-amt"
                                                                                style={{ marginLeft: 50 }}
                                                                            >
                                                                                <a href="#">
                                                                                    <i
                                                                                        className="fa fa-inr"
                                                                                        aria-hidden="true"
                                                                                    ></i>{" "}
                                                                                    {price - discount_less}
                                                                                </a>
                                                                                <p>( inclusive of all taxes )</p>
                                                                            </div>
                                                                        </div>

                                                                        <div className="product-price-container-mobile mobile-responisve">
                                                                            <div>
                                                                                <div className="price-info">
                                                                                    <div
                                                                                        className="mrp-price"
                                                                                        style={{ marginTop: 0 }}
                                                                                    >
                                                                                        <p className="mrp-rate">
                                                                                            M.R.P
                                                                                            <s style={{ marginLeft: 8 }}>
                                                                                                <i
                                                                                                    className="fa fa-inr"
                                                                                                    aria-hidden="true"
                                                                                                >
                                                                                                    {" "}
                                                                                                </i>{" "}
                                                                                                {price}
                                                                                            </s>
                                                                                        </p>
                                                                                        <p className="offer-percentage">
                                                                                            {discount}% off
                                                                                        </p>
                                                                                    </div>
                                                                                    <div className="bg-btn">
                                                                                        <a href="#">
                                                                                            <span>You Save</span>{" "}
                                                                                            <i
                                                                                                className="fa fa-inr"
                                                                                                aria-hidden="true"
                                                                                            ></i>{" "}
                                                                                            {discount_less}
                                                                                        </a>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div className="current-amt">
                                                                                <a href="#">
                                                                                    <i
                                                                                        className="fa fa-inr"
                                                                                        aria-hidden="true"
                                                                                    ></i>{" "}
                                                                                    {price - discount_less}
                                                                                </a>
                                                                                <p>( inclusive of all taxes )</p>
                                                                            </div>
                                                                        </div>

                                                                        <div
                                                                            className="has-border"
                                                                            style={
                                                                                type_sub_devision === "0"
                                                                                    ? { display: "none" }
                                                                                    : { display: "block" }
                                                                            }
                                                                        >
                                                                            <label>TYPE:</label>
                                                                            <div className="height-sec-container_big">
                                                                                {subdivision.length > 0
                                                                                    ? subdivision.map(
                                                                                        (element_sub, index_sub) => (
                                                                                            <div key={index_sub}
                                                                                                className={
                                                                                                    index_sub === heightmenu
                                                                                                        ? "heigh-div-wraper-active"
                                                                                                        : "heigh-div-wraper"
                                                                                                }
                                                                                            >
                                                                                                <button
                                                                                                    onClick={() => {
                                                                                                        toggleButton(
                                                                                                            index_sub,
                                                                                                            element_sub.subdivision
                                                                                                        );
                                                                                                    }}
                                                                                                >
                                                                                                    {element_sub.subdivision.toUpperCase()}
                                                                                                </button>
                                                                                            </div>
                                                                                        )
                                                                                    )
                                                                                    : ""}
                                                                            </div>
                                                                        </div>

                                                                        <div className="has-border ">
                                                                            <label>HEIGHT IN INCHES</label>
                                                                            <div className="height-sec-container">
                                                                                {thickness.length > 0
                                                                                    ? thickness.map(
                                                                                        (element_thickens, index) => (
                                                                                            <div key={index}
                                                                                                className={
                                                                                                    index === heightmenu2
                                                                                                        ? "heigh-div-wraper-active"
                                                                                                        : "heigh-div-wraper"
                                                                                                }
                                                                                            >
                                                                                                <button
                                                                                                    onClick={() => {
                                                                                                        set_height(
                                                                                                            element_thickens.thickness
                                                                                                        );
                                                                                                        toggleButton2(
                                                                                                            index,
                                                                                                            element_thickens.thickness
                                                                                                        );
                                                                                                    }}
                                                                                                >
                                                                                                    {element_thickens.thickness.toUpperCase()}
                                                                                                </button>
                                                                                            </div>
                                                                                        )
                                                                                    )
                                                                                    : ""}
                                                                            </div>
                                                                        </div>

                                                                        <div
                                                                            className="has-border "
                                                                            style={{ paddingBottom: 0 }}
                                                                        >
                                                                            <label>
                                                                                SIZE ( DIMENTION IN INCHES )
                                                                            </label>
                                                                            <div className="height-sec-container">
                                                                                {bed_Data.length > 0
                                                                                    ? bed_Data.map((element, index) => (
                                                                                        <div key={index}
                                                                                            className={
                                                                                                index === heightmenu3
                                                                                                    ? "heigh-div-wraper-active"
                                                                                                    : "heigh-div-wraper"
                                                                                            }
                                                                                        >
                                                                                            <button
                                                                                                onClick={() => {
                                                                                                    toggleButton3(
                                                                                                        index,
                                                                                                        element.bed_type
                                                                                                    );
                                                                                                    // custest(element.bed_type)
                                                                                                }}
                                                                                            >
                                                                                                {element.bed_type.toUpperCase()}
                                                                                            </button>
                                                                                        </div>
                                                                                    ))
                                                                                    : ""}
                                                                            </div>
                                                                        </div>
                                                                        <hr />

                                                                        <div className="has-border ">
                                                                            <div
                                                                                style={{
                                                                                    display: "flex",
                                                                                    flexDirection: "row",
                                                                                    marginTop: 17,
                                                                                    marginBottom: 10,
                                                                                }}
                                                                            >
                                                                                <select
                                                                                    style={{ fontSize: "16 !important" }}
                                                                                    onChange={(event) =>
                                                                                        diment_click(event.target.value)
                                                                                    }
                                                                                    className="form-controll slectDimention"
                                                                                >
                                                                                    {dimension.length > 0
                                                                                        ? dimension.map(
                                                                                            (element_dimention, index) => (
                                                                                                <option key={index}
                                                                                                    value={
                                                                                                        element_dimention.product_dimensions
                                                                                                    }
                                                                                                >
                                                                                                    {
                                                                                                        element_dimention.product_dimensions}
                                                                                                </option>
                                                                                            )
                                                                                        )
                                                                                        : ""}
                                                                                </select>
                                                                                <span
                                                                                    className="custom-Button"
                                                                                    onClick={() => toggleCustomsize()}
                                                                                >
                                                                                    {" "}
                                                                                    Get Custom Size
                                                                                </span>
                                                                            </div>
                                                                            <span style={{ color: "#AEAEAE" }}>
                                                                                Note : Select Appropriate Size Before
                                                                                Proceeding
                                                                            </span>
                                                                        </div>

                                                                        <div className="d-flex2 ">
                                                                            <form id="create-course-form">
                                                                                <div
                                                                                    id="custom-size"
                                                                                    className="custom-size"
                                                                                    hidden
                                                                                >
                                                                                    <div className="custom-size-filed-wrapper">
                                                                                        <label>LENGTH (in)</label>
                                                                                        <input
                                                                                            type="text"
                                                                                            id="cus_width"
                                                                                            onChange={(event) =>
                                                                                                set_custom_height(
                                                                                                    event.target.value
                                                                                                )
                                                                                            }
                                                                                        />
                                                                                    </div>

                                                                                    <div className="custom-size-filed-wrapper">
                                                                                        <label>WIDTH (in)</label>
                                                                                        <input
                                                                                            type="text"
                                                                                            id="cus_width"
                                                                                            onChange={(event) =>
                                                                                                set_custom_width(
                                                                                                    event.target.value
                                                                                                )
                                                                                            }
                                                                                        />
                                                                                    </div>

                                                                                    <div className="extra_class">
                                                                                        <p>
                                                                                            * Length customization is allowed
                                                                                            between 68 - 84 inch
                                                                                        </p>
                                                                                        <p>
                                                                                            * Width customization is allowed
                                                                                            between 30 - 72 inch
                                                                                        </p>

                                                                                        <p>
                                                                                            * Please round down the size (For
                                                                                            eg. if the size that you need is
                                                                                            74.6 please make it 74).
                                                                                        </p>
                                                                                    </div>
                                                                                </div>
                                                                            </form>


                                                                        </div>

                                                                        <div className="has-border ">
                                                                            <label>
                                                                                SELECTED COLOR - {pickedColor}
                                                                            </label>
                                                                            <div className="height-sec-container">
                                                                                {coloursOptions.length > 0
                                                                                    ? coloursOptions.map(
                                                                                        (element, index) => (
                                                                                            <div key={index}
                                                                                                className={
                                                                                                    element.id === pickedColorId
                                                                                                        ? "heigh-div-wraper-color-active"
                                                                                                        : "heigh-div-wraper-color"
                                                                                                }
                                                                                                onClick={() => {
                                                                                                    onOpenModal(element);
                                                                                                }}
                                                                                            >
                                                                                                <img
                                                                                                    onClick={() => {
                                                                                                        onOpenModal(element);
                                                                                                    }}
                                                                                                    src={imgurl + element.img}
                                                                                                    alt={
                                                                                                        Product_Details.product_imageurl_alt
                                                                                                    }
                                                                                                />
                                                                                            </div>
                                                                                        )
                                                                                    )
                                                                                    : "No Colors Available"}
                                                                            </div>
                                                                        </div>

                                                                        <div className="pr-coupon mobile-responisve">
                                                                            <div
                                                                                style={{
                                                                                    display: "flex",
                                                                                    cursor: "pointer",
                                                                                }}
                                                                                onClick={() =>
                                                                                    toggleCouponButtonMobile()
                                                                                }
                                                                            >
                                                                                <img
                                                                                    src={CoupenPng}
                                                                                    alt={"Coupen"}
                                                                                    width={20}
                                                                                    height={20}
                                                                                />

                                                                                <label
                                                                                    style={{
                                                                                        cursor: "pointer",
                                                                                        marginLeft: 10,
                                                                                    }}
                                                                                >
                                                                                    APPLY COUPON CODE
                                                                                </label>
                                                                            </div>
                                                                            <div
                                                                                id="coupon-section"
                                                                                className="coupon-section"
                                                                                hidden
                                                                            >
                                                                                <textarea
                                                                                    onChange={(event) =>
                                                                                        set_newcoupen(event.target.value)
                                                                                    }
                                                                                ></textarea>
                                                                                <a
                                                                                    onClick={copenApply}
                                                                                    style={{ color: "white" }}
                                                                                >
                                                                                    Check
                                                                                </a>
                                                                            </div>
                                                                        </div>

                                                                        <div className="mobile-responisve">
                                                                            <hr />
                                                                        </div>
                                                                        <div className="free-area-style mobile-responisve">
                                                                            <Lottie
                                                                                options={defaultOptions}
                                                                                height={100}
                                                                                width={150}
                                                                                isStopped={false}
                                                                                isPaused={false}
                                                                            />
                                                                            <div>
                                                                                {" "}
                                                                                <div
                                                                                    style={{
                                                                                        textAlign: "center",
                                                                                        marginBottom: 10,
                                                                                    }}
                                                                                >
                                                                                    <span>
                                                                                        Hurray ! Free sleep accessories !
                                                                                    </span>
                                                                                </div>
                                                                                <p> {free_content}</p>
                                                                            </div>
                                                                        </div>


                                                                        <div className="buy-section mobile-responisve">
                                                                            <a

                                                                                onClick={() =>
                                                                                    addTocart(
                                                                                        id,
                                                                                        price - discount_less,
                                                                                        Product_Details.product_name,
                                                                                        Product_Details.product_imageurl,
                                                                                        local,
                                                                                        Dimen,
                                                                                        height,
                                                                                        Product_Details.free_content,
                                                                                        pickedColor,
                                                                                        discount,
                                                                                        price
                                                                                    )
                                                                                }
                                                                                style={{ color: "white" }}
                                                                                className={`add-cart  ${loader ? 'pointer-events-none' : ''}`}
                                                                            >
                                                                                {loader ? 'loading...' : ' ADD TO CART'}

                                                                            </a>
                                                                            <a
                                                                                style={{ color: "white" }}
                                                                                className={`buy-now  ${loader ? 'pointer-events-none' : ''}`}
                                                                                onClick={() => {
                                                                                    addTocart(
                                                                                        id,
                                                                                        price - discount_less,
                                                                                        Product_Details.product_name,
                                                                                        Product_Details.product_imageurl,
                                                                                        local,
                                                                                        Dimen,
                                                                                        height,
                                                                                        Product_Details.free_content
                                                                                    );
                                                                                    buy_func();
                                                                                }}
                                                                            >
                                                                                {loader ? 'loading...' : 'BUY NOW'}

                                                                            </a>
                                                                        </div>

                                                                        <div
                                                                            style={{ display: "none" }}
                                                                            className="has-border cart-area desktop-responisve"
                                                                        >

                                                                            <div className="payment-sec-container">
                                                                                <div className="payment-sect-left">
                                                                                    <p>MRP ₹ {price}</p>

                                                                                    <p>MRP ₹ {price - discount_less} </p>

                                                                                    <p>{discount}% Discounted</p>
                                                                                    <p>You Save ₹ {discount_less}</p>
                                                                                </div>

                                                                                <div className="payment-sect-right">
                                                                                    <p
                                                                                        style={{
                                                                                            display: coupenstatus
                                                                                                ? "block"
                                                                                                : "none",
                                                                                        }}
                                                                                    >
                                                                                        Coupon Applied - '{new_dbcoupen}'
                                                                                    </p>
                                                                                    <p style={{ fontSize: "24px" }}>
                                                                                        Selling Price
                                                                                    </p>
                                                                                    <div className="spl-pri-wraper heart">
                                                                                        ₹ {price - discount_less}
                                                                                    </div>
                                                                                </div>
                                                                            </div>

                                                                            <div className="product-quantity">
                                                                                <div className="qty">
                                                                                    <div className="input-group input-group-cus">
                                                                                        <span className="add">
                                                                                            <button
                                                                                                className="btn btn-primary add-to-cart add-item"
                                                                                                data-button-action="add-to-cart"
                                                                                                type="submit"
                                                                                                onClick={() =>
                                                                                                    addTocart(
                                                                                                        id,
                                                                                                        price - discount_less,
                                                                                                        Product_Details.product_name,
                                                                                                        Product_Details.product_imageurl,
                                                                                                        local,
                                                                                                        Dimen,
                                                                                                        height,
                                                                                                        Product_Details.free_content,
                                                                                                        pickedColor,
                                                                                                        discount,
                                                                                                        price
                                                                                                    )
                                                                                                }
                                                                                            >
                                                                                                <i
                                                                                                    className="fa fa-shopping-cart"
                                                                                                    aria-hidden="true"
                                                                                                ></i>
                                                                                                <span>Add to cart</span>
                                                                                            </button>
                                                                                            <a
                                                                                                className="addToWishlist"
                                                                                                onClick={() =>
                                                                                                    wishlist_add(
                                                                                                        id,
                                                                                                        price - discount_less,
                                                                                                        Product_Details.product_name,
                                                                                                        Product_Details.product_imageurl,
                                                                                                        local,
                                                                                                        Dimen,
                                                                                                        height
                                                                                                    )
                                                                                                }
                                                                                            >
                                                                                                <i
                                                                                                    className="fa fa-heart"
                                                                                                    aria-hidden="true"
                                                                                                ></i>
                                                                                            </a>
                                                                                        </span>
                                                                                        <button
                                                                                            className="buynow"
                                                                                            disabled={loader ? true : false}
                                                                                            onClick={() => {
                                                                                                addTocart(
                                                                                                    id,
                                                                                                    price - discount_less,
                                                                                                    Product_Details.product_name,
                                                                                                    Product_Details.product_imageurl,
                                                                                                    local,
                                                                                                    Dimen,
                                                                                                    height,
                                                                                                    Product_Details.free_content,
                                                                                                    pickedColor,
                                                                                                    discount,
                                                                                                    price
                                                                                                );
                                                                                                buy_func();
                                                                                            }}
                                                                                        >

                                                                                            {loader ? 'Loading...' : 'Buy Now'}
                                                                                        </button>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div className="clearfix"></div>
                                                                            <p className="product-minimal-quantity"></p>

                                                                            <div className="cus-coupon-apply-container">
                                                                                <div className="custom-coupon-apply-button">
                                                                                    <button
                                                                                        onClick={() => toggleCouponButton()}
                                                                                    >
                                                                                        {" "}
                                                                                        Apply Coupon
                                                                                    </button>
                                                                                </div>
                                                                                <div
                                                                                    id="coupon-section"
                                                                                    className="coupon-section"
                                                                                    hidden
                                                                                >
                                                                                    <input
                                                                                        type="text"
                                                                                        onChange={(event) =>
                                                                                            set_newcoupen(event.target.value)
                                                                                        }
                                                                                    />
                                                                                    <button onClick={copenApply}>
                                                                                        Apply
                                                                                    </button>
                                                                                    <button onClick={copenApply}>
                                                                                        Cancel
                                                                                    </button>
                                                                                </div>
                                                                            </div>
                                                                        </div>


                                                                        <div className="pr-coupon desktop-responisve">
                                                                            <div
                                                                                style={{
                                                                                    display: "flex",
                                                                                    cursor: "pointer",
                                                                                }}
                                                                                onClick={() => toggleCouponButton()}
                                                                            >
                                                                                <img
                                                                                    src={CoupenPng}
                                                                                    alt={"Coupen"}
                                                                                    width={20}
                                                                                    height={20}
                                                                                />

                                                                                <label
                                                                                    style={{
                                                                                        cursor: "pointer",
                                                                                        marginLeft: 10,
                                                                                        color: "#992876",
                                                                                    }}
                                                                                    className="blink_me_lab"
                                                                                >
                                                                                    APPLY COUPON CODE
                                                                                </label>
                                                                            </div>
                                                                            <div
                                                                                id="coupon-section1"
                                                                                className="coupon-section"
                                                                                hidden
                                                                            >
                                                                                <textarea
                                                                                    onChange={(event) =>
                                                                                        set_newcoupen(event.target.value)
                                                                                    }
                                                                                ></textarea>
                                                                                <a
                                                                                    onClick={copenApply}
                                                                                    style={{ color: "white" }}
                                                                                >
                                                                                    Check
                                                                                </a>
                                                                            </div>
                                                                        </div>

                                                                        {price ?

                                                                            <RazorpayWidget razorpayKey={'rzp_live_MCn1tClDeCveBq'} amount={price - discount_less} />
                                                                            :
                                                                            <></>
                                                                        }

                                                                        <div
                                                                            style={{
                                                                                marginTop: 20,
                                                                                marginBottom: 10,
                                                                            }}
                                                                        >
                                                                            {" "}
                                                                            <p style={{ fontSize: 14 }}>
                                                                                * Free Delivery within 2 to 5 Days
                                                                                across South India !
                                                                            </p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <ReviewComponent reviewData={customer_review_list} cus_length={customer_review_list.length} desc_img={imgurl + description_image} img_alt={Product_Details.description_main_image_alt} desc={desc} formHandler={handleReviewForm} reviewSubmit={handleSubmitReview} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="cus-bt-container">
                    <div className="row">
                        <div id="cus-img" className="col-lg-6 col-md-6 col-xs-12 bg-cus">
                            <div className="product-dt-bottom">
                                <img
                                    src={imgurl + top_img}
                                    alt={Product_Details.description_top_image_alt}
                                />
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-6 col-xs-12 bg-cus">
                            <div className="product-dt-bottom-cnt">
                                <h3>{top_header}</h3>
                                <p>{top_content}</p>
                            </div>
                        </div>
                    </div>
                    <div className="row left-right">
                        <div className="col-lg-6 col-md-6 col-xs-12 bg-cus">
                            <div className="product-dt-bottom-cnt">
                                <h3>{bottom_header}</h3>
                                <p>{bottom_content}</p>
                            </div>
                        </div>
                        <div id="cus-img" className="col-lg-6 col-md-6 col-xs-12 bg-cus">
                            <div className="product-dt-bottom">
                                <img
                                    src={imgurl + bot_img}
                                    alt={Product_Details.description_bottom_image_alt}
                                />
                            </div>
                        </div>
                    </div>
                </div>

                {related()}

                {/* <ProductSlider detailsData={Product_Details} title="Related Products" />  */}

                <footer className="mobile-responisve tw-w-full">
                    <div className="add-to-cart-section tw-justify-center tw-flex-1">
                        <div className="price-view">
                            <p className="mrp-rate">
                                M.R.P{" "}
                                <i className="fa fa-inr">
                                    <s> {price}</s>
                                </i>
                            </p>
                            <a href="#">
                                <i className="fa fa-inr"></i> {price - discount_less}
                            </a>
                        </div>
                        <a
                            onClick={() => {
                                addTocart(
                                    id,
                                    price - discount_less,
                                    Product_Details.product_name,
                                    Product_Details.product_imageurl,
                                    local,
                                    Dimen,
                                    height,
                                    Product_Details.free_content,
                                    pickedColor,
                                    discount,
                                    price
                                );
                                buy_func();
                            }

                            }
                            className={`${loader ? 'pointer-events-none' : ''} add-to-cart-btn`}
                            // href="#"
                            style={{ color: "white", backgroundColor: 'black' }}
                        >
                            {loader ? 'loading...' : ' Buy Now'}

                        </a>
                    </div>

                    <Modal
                        open={open}
                        onClose={onCloseModal}
                        center
                        classNames={{
                            overlayAnimationIn: "customEnterOverlayAnimation",
                            overlayAnimationOut: "customLeaveOverlayAnimation",
                            modalAnimationIn: "customEnterModalAnimation",
                            modalAnimationOut: "customLeaveModalAnimation",
                        }}
                        animationDuration={800}
                    >
                        <img
                            src={imgurl + pickedColorImg}
                            style={{ width: "75%", height: "75%" }}
                        />
                    </Modal>
                </footer>
            </div>
            <Loader />

            {/* Bootom Tab Code Starts */}
            <>
                <div className="tw-fixed tw-hidden md:tw-block lg:tw-block xl:tw-block tw-bottom-0   tw-w-full tw-z-50 tw-px-5  tw-bg-[#eee] tw-shadow-lg tw-border-t-0 tw-pt-5 tw-pb-3 tw-rounded-r-3xl ">
                    <div className="tw-grid tw-grid-cols-2">
                        <div>
                            <div className="price-view tw-flex tw-items-center tw-justify-center">
                                <div>
                                    <p className="tw-font-bold tw-text-[#982876] tw-text-[18px]" >
                                        * Price of{" "}
                                        {custom_height === "" || custom_width === ""
                                            ? local + " " + Dimen + " X " + height
                                            : "Custom" +
                                            " " +
                                            custom_height +
                                            " x " +
                                            custom_width +
                                            " X " +
                                            height}
                                    </p>
                                </div>
                                <div className="price-view tw-flex tw-items-center tw-justify-center tw-flex-row tw-ml-5 tw-gap-5">
                                    <div className="tw-flex tw-gap-3 tw-items-center tw-text-[20px] tw-text-black">
                                        <p>
                                            M.R.P{" "}
                                        </p>
                                        <div className="tw-flex tw-items-center tw-gap-1">
                                        <i style={{ paddingLeft: 10 }} className="fa fa-inr" />
                                        <p className="mrp-rate tw-line-through">
                                            {price}
                                        </p>
                                        </div>
                                    </div>
                                    <div className="tw-flex tw-justify-center tw-items-center tw-gap-3">

                                        <i className="fa fa-inr tw-text-black tw-text-[24px]" width={20} heigh={20}></i>
                                        <p
                                            className=" tw-text-[24px] tw-text-black tw-font-bold "
                                            style={{ fontFamily: "system-ui" }}
                                        >
                                            {price - discount_less}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="tw-grid tw-grid-cols-2 input-group-cus">
                            <button
                                className="buynow"
                                data-button-action="add-to-cart"
                                type="submit"
                                disabled={loader ? true : false}

                                onClick={() =>
                                    addTocart(
                                        id,
                                        price - discount_less,
                                        Product_Details.product_name,
                                        Product_Details.product_imageurl,
                                        local,
                                        Dimen,
                                        height,
                                        Product_Details.free_content,
                                        pickedColor,
                                        discount,
                                        price
                                    )
                                }
                            >
                                <i
                                    className="fa fa-shopping-cart"
                                    aria-hidden="true"
                                    style={{ marginRight: 10 }}
                                ></i>
                                <span>{loader ? 'loading...' : 'Add to cart'} </span>
                            </button>

                            <button
                                className="buynow"
                                style={{
                                    background: "black",
                                    marginLeft: 15,
                                }}
                                disabled={loader ? true : false}

                                onClick={() => {
                                    addTocart(
                                        id,
                                        price - discount_less,
                                        Product_Details.product_name,
                                        Product_Details.product_imageurl,
                                        local,
                                        Dimen,
                                        height,
                                        Product_Details.free_content,
                                        pickedColor,
                                        discount,
                                        price
                                    );
                                    buy_func();
                                }}
                            >
                                {loader ? 'loading...' : 'Buy Now'}

                            </button>
                        </div>
                    </div>
                </div>
            </>
        </>
    );
}


const ReviewComponent = ({ reviewData, cus_length, desc_img, img_alt, desc, formHandler, reviewSubmit }) => {
    return (
        <div className="review">
            <ul className="nav nav-tabs">
                <li className="active">
                    <a data-toggle="tab" href="#description" className="active ">
                        Description
                    </a>
                </li>

                <li>
                    <a data-toggle="tab" href="#review">
                        Reviews ({cus_length})
                    </a>
                </li>
            </ul>

            <div className="tab-content">
                <div
                    id="description"
                    className="tab-pane fade in active show "
                >
                    <div className="desc">
                        <img
                            className="desc1"
                            src={desc_img}
                            alt={img_alt}
                        />
                    </div>

                    <div
                        dangerouslySetInnerHTML={{ __html: desc }}
                    />
                </div>

                <div id="review" className="tab-pane fade">
                    <div className="spr-form">
                        <div
                            className="user-comment"
                            style={{ margin: 10 }}
                        >
                            {reviewData.length > 0
                                ? reviewData.map(
                                    (element_com, index) => (
                                        <div key={index} className="spr-review">
                                            <div className="spr-review-header">
                                                <span className="spr-review-header-byline">
                                                    <strong>
                                                        {element_com.name.toUpperCase()}
                                                    </strong>{" "}
                                                    -
                                                    <span>
                                                        {element_com.date_time}
                                                    </span>
                                                </span>
                                            </div>
                                            <div className="spr-review-content">
                                                <p className="spr-review-content-body">
                                                    {element_com.comment}
                                                </p>
                                            </div>
                                        </div>
                                    )
                                )
                                : "No Reviews Founded!"}
                        </div>
                    </div>
                    <form id="review-form">
                        <input
                            type="hidden"
                            name="review[rating]"
                            value="3"
                        />
                        <input type="hidden" name="product_id" />
                        <h3 className="spr-form-title">
                            Write a review
                        </h3>

                        <fieldset className="spr-form-contact">
                            <div className="spr-form-contact-name">
                                <input
                                    className="spr-form-input spr-form-input-text form-control"
                                    name="name"
                                    placeholder="Enter your name"
                                    onChange={(event) =>
                                        formHandler(event)
                                    }
                                />
                            </div>
                            <div className="spr-form-contact-email">
                                <input
                                    className="spr-form-input "
                                    placeholder="Enter your email"
                                    name="email"
                                    onChange={(event) =>
                                        formHandler(event)
                                    }
                                />
                            </div>
                        </fieldset>
                        <fieldset>
                            <div className="spr-form-review-body">
                                <div className="spr-form-input">
                                    <textarea
                                        className="spr-form-input-textarea"
                                        rows="10"
                                        name="comments"
                                        placeholder="Write your comments here"
                                        onChange={(event) =>
                                            formHandler(event)
                                        }
                                    ></textarea>
                                </div>
                            </div>
                        </fieldset>
                        <div
                            className="submit"
                            style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                marginBottom: "10px",
                            }}
                        >
                            <input
                                type="button"
                                name="addComment"
                                id="submitComment"
                                className="btn btn-default"
                                value="Submit Review"
                                onClick={() => {
                                    reviewSubmit();
                                }}
                            />
                        </div>
                    </form>
                </div>
                <div id="tag" className="tab-pane fade in">
                    <p>
                        Lorem ipsum dolor sit amet, consectetur
                        adipisicing elit, sed do eiusmod tempor
                        incididunt ut labore et dolore magna
                        aliqua.Lorem ipsum dolor sit amet,
                        consectetur adipisicing elit, sed do
                        eiusmod tempor incididunt ut labore et
                        dolore magna aliqua.
                    </p>
                    <p>
                        Lorem ipsum dolor sit amet, consectetur
                        adipisicing elit, sed do eiusmod tempor
                        incididunt ut labore et dolore magna
                        aliqua.Lorem ipsum dolor sit amet,
                        consectetur adipisicing elit, sed do
                        eiusmod tempor incididunt ut labore et
                        dolore magna aliqua.
                    </p>
                </div>
            </div>
        </div>
    )
}

const ProductLeftSide = ({productImage_data, freecontent})=>{
    return(
        <div className="product-detail col-xs-12 col-md-5 col-sm-5">
        <div className="page-content" id="content">
            <div className="images-container">
                <div className="js-qv-mask mask tab-content ">
                    <div
                        id="item1"
                        className="tab-pane fade active in show"
                    >
                        <img
                            src={imgurl + productImage_data.image_1}
                            alt={productImage_data.product_alt_1}
                        />
                    </div>
                    <div id="item2" className="tab-pane fade">
                        <img
                            src={imgurl + productImage_data.image_2}
                            alt={productImage_data.product_alt_2}
                        />
                    </div>
                    <div id="item3" className="tab-pane fade">
                        <img
                            src={imgurl + productImage_data.image_3}
                            alt={productImage_data.product_alt_3}
                        />
                    </div>
                    <div id="item4" className="tab-pane fade">
                        <img
                            src={imgurl + productImage_data.image_4}
                            alt={productImage_data.product_alt_4}
                        />
                    </div>
                    <div
                        className="layer hidden-sm-down desktop-responisve"
                        data-toggle="modal"
                        data-target="#product-modal"
                    >
                        <i className="fa fa-expand"></i>
                    </div>
                </div>
                <ul className="product-tab nav nav-tabs d-flex">
                    <li className="active col">
                        <a
                            href="#item1"
                            data-toggle="tab"
                            aria-expanded="true"
                            className="active "
                        >
                            <img
                                src={imgurl + productImage_data.image_1}
                                alt={productImage_data.product_alt_1}
                            />
                        </a>
                    </li>
                    <li className="col">
                        <a href="#item2" data-toggle="tab">
                            <img
                                src={imgurl + productImage_data.image_2}
                                alt={productImage_data.product_alt_2 }
                            />
                        </a>
                    </li>
                    <li className="col">
                        <a href="#item3" data-toggle="tab">
                            <img
                                src={imgurl + productImage_data.image_3}
                                alt={productImage_data.product_alt_3}
                            />
                        </a>
                    </li>
                    <li className="col">
                        <a href="#item4" data-toggle="tab">
                            <img
                                src={imgurl + productImage_data.image_4}
                                alt={productImage_data.product_alt_4}
                            />
                        </a>
                    </li>
                </ul>
                <div
                    className="modal fade"
                    id="product-modal"
                    role="dialog"
                >
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <div className="modal-body">
                                    <div className="product-detail">
                                        <div>
                                            <div className="images-container">
                                                <div className="js-qv-mask mask tab-content">
                                                    <div
                                                        id="modal-item1"
                                                        className="tab-pane fade active in "
                                                    >
                                                        <img
                                                            src={
                                                                imgurl + productImage_data.image_1
                                                            }
                                                            alt={productImage_data.product_alt_1}
                                                        />
                                                    </div>
                                                    <div
                                                        id="modal-item2"
                                                        className="tab-pane fade"
                                                    >
                                                        <img
                                                            src={
                                                                imgurl + productImage_data.image_2
                                                            }
                                                            alt={productImage_data.product_alt_2}
                                                        />
                                                    </div>
                                                    <div
                                                        id="modal-item3"
                                                        className="tab-pane fade"
                                                    >
                                                        <img
                                                            src={
                                                                imgurl + productImage_data.image_3
                                                            }
                                                            alt={productImage_data.product_alt_3}
                                                        />
                                                    </div>
                                                    <div
                                                        id="modal-item4"
                                                        className="tab-pane fade"
                                                    >
                                                        <img
                                                            src={
                                                                imgurl + productImage_data.image_4
                                                            }
                                                            alt={productImage_data.product_alt_4}
                                                        />
                                                    </div>
                                                </div>
                                                <ul className="product-tab nav nav-tabs">
                                                    <li className="active">
                                                        <a
                                                            href="#modal-item1"
                                                            data-toggle="tab"
                                                            className=" active "
                                                        >
                                                            <img
                                                                src={
                                                                    imgurl + productImage_data.image_1
                                                                }
                                                                alt={productImage_data.product_alt_1}
                                                            />
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a
                                                            href="#modal-item2"
                                                            data-toggle="tab"
                                                        >
                                                            <img
                                                                src={
                                                                    imgurl + productImage_data.image_2
                                                                }
                                                                alt={productImage_data.product_alt_2}
                                                            />
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a
                                                            href="#modal-item3"
                                                            data-toggle="tab"
                                                        >
                                                            <img
                                                                src={
                                                                    imgurl + productImage_data.image_3
                                                                }
                                                                alt={productImage_data.product_alt_3}
                                                            />
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a
                                                            href="#modal-item4"
                                                            data-toggle="tab"
                                                        >
                                                            <img
                                                                src={
                                                                    imgurl + productImage_data.image_4
                                                                }
                                                                alt={productImage_data.product_alt_4}
                                                            />
                                                        </a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div className="desktop-responisve">
            <hr />
        </div>
        <div className="free-area-style desktop-responisve">
            <Lottie
                options={defaultOptions}
                height={80}
                width={150}
                isStopped={false}
                isPaused={false}
            />
            <div>
                {" "}
                <span>
                    Hurray, you’ve unlocked the
                    complementary sleep accessories !
                </span>
                <p> {freecontent}</p>
            </div>
        </div>
    </div>
    )
}