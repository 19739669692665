import React from "react";
import { useNavigate } from "react-router-dom";

function SidebarMbl() {



  if (localStorage.getItem('userInfo') != null) {

    var userData = JSON.parse(localStorage.getItem('userInfo'));
  }
  else {
    var userData = "";
  }


  let navigate = useNavigate();

  const warranty_page = () => {
    navigate('/warranty');
  }

  const wishlist = () => {
    navigate('/list');
  }



  const blog = () => {
    navigate('/blog');
  }

  const about = () => {
    navigate('/about');
  }

  const Accessories = () => {
    navigate('/accessories/all');
  }

  const Mattress = () => {
    navigate('/productlist/all');
  }



  const contact = () => {
    navigate('/contact');
  }
  const signup = () => {
    navigate('/register');
  }
  const signin = () => {
    navigate('/login');
  }

  const signout = () => {


    localStorage.removeItem("userInfo");

    navigate('/login');

  }

  return (
    <div className="mobile-top-menu d-md-none">
      <button type="button" className="close" aria-label="Close">
        <i className="zmdi zmdi-close"></i>
      </button>
      <div className="tiva-verticalmenu block" data-count_showmore="17">
        <div className="box-content block-content">
          <div className="verticalmenu" role="navigation">
            <ul className="menu level1">


              <li className="item ">
                <a style={{ cursor: 'pointer' }} className="hasicon" title="SIDE TABLE" onClick={Mattress} target="_blank" >

                  <i className="fa fa-bookmark-o" style={{ marginRight: "15px" }}></i>

                  Mattress
                </a>

              </li>


              <li className="item ">
                <a style={{ cursor: 'pointer' }} className="hasicon" title="SIDE TABLE" onClick={Accessories} target="_blank" >

                  <i className="fa fa-bookmark-o" style={{ marginRight: "15px" }}></i>

                  Accessories
                </a>

              </li>

              <li className="item">
                <a style={{ cursor: 'pointer' }} className="hasicon" title="SIDE TABLE" onClick={about}>
                  <i className="fa fa-info-circle" style={{ marginRight: "15px" }}></i>
                  About Us
                </a>
              </li>
              <li className="item">
                <a style={{ cursor: 'pointer' }} className="hasicon" title="SIDE TABLE" onClick={contact}>
                  <i className="fa fa-address-book" style={{ marginRight: "15px" }}></i>
                  Contact Us
                </a>
              </li>
              <li className="item">
                <a style={{ cursor: 'pointer' }} className="hasicon" title="SIDE TABLE" onClick={warranty_page}>

                  <i className="fa fa-check-circle-o" style={{ marginRight: "15px" }}></i>
                  Warranty

                </a>
              </li>


              <li className="item">
                <a style={{ cursor: 'pointer' }} className="hasicon" title="SIDE TABLE" onClick={blog}>

                  <i className="fa fa-commenting-o" style={{ marginRight: "15px" }}></i>
                  Blog
                </a>
              </li>

              <li className="item ">
                <a style={{ cursor: 'pointer' }} className="hasicon" title="SIDE TABLE" href="https://admin.poppyindia.com/a_assets/catelog/Catalog.pdf" target="_blank" >
                  <i className="fa fa-book" aria-hidden="true" style={{ marginRight: "15px" }}></i>

                  Catelogue
                </a>

              </li>

              <li className="item ">
                <a style={{ cursor: 'pointer' }} className="hasicon" title="SIDE TABLE" onClick={wishlist}>

                  <i className="fa fa-heart" style={{ marginRight: "15px" }}></i>
                  Wishlist
                </a>

              </li>

              <li className="item " style={{ display: userData != "" || userData === "undefined" ? 'none' : 'block' }}>
                <a style={{ cursor: 'pointer' }} className="hasicon" title="SIDE TABLE" onClick={signup}>

                  <i className="fa fa-sign-in" style={{ marginRight: "15px" }}></i>
                  Signup
                </a>

              </li>

              <li className="item " style={{ display: userData != "" || userData === "undefined" ? 'none' : 'block' }}>
                <a style={{ cursor: 'pointer' }} className="hasicon" title="SIDE TABLE" onClick={signin}>

                  <i className="fa fa-sign-in" style={{ marginRight: "15px" }}></i>
                  Signin
                </a>

              </li>


              <li className="item " style={{ display: userData === "" ? 'none' : 'block' }}>
                <a style={{ cursor: 'pointer' }} className="hasicon" title="SIDE TABLE" onClick={signout}>

                  <i className="fa fa-sign-out" style={{ marginRight: "15px" }}></i>
                  Signout
                </a>

              </li>






            </ul>
          </div>
        </div>
      </div>

    </div>

  )
}

export default SidebarMbl
