import "./styles/popup.css";
import React, { useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

const imgurl = process.env.REACT_APP_IMG_URL;
const baseurl = process.env.REACT_APP_BASE_URL;

function Popup() {
  const navigate = useNavigate();
  const [popupState, setPopupState] = useState(false);
  const [popupImage, setPopupImage] = useState("");
  const [urlSet, setUrlSet] = useState("");

  useEffect(() => {
    // Check if the popup can be shown based on the last display time
    checkPopupDisplay();
  }, []);

  const checkPopupDisplay = () => {
    const lastShown = localStorage.getItem('popupLastShown');
    const now = new Date().getTime(); // Get current time in milliseconds

    // If last shown time exists and less than an hour has passed
    // if (lastShown && now - lastShown < 3600000) { // 3600000 ms = 1 hour
    //   return; // Don't show the popup
    // }

    // Fetch popup data if eligible to show
    popupApi();
  };

  const popupApi = async () => {
    try {
      const { data } = await axios.get(baseurl + "user/popup");
      const image = data.data[0].image.replace(/\s/g, "%20");
      setPopupImage(image);
      setUrlSet(data.data[0].url);
      if (image) setTimeout(() => setPopupState(true), 4000);
    } catch (error) {
      console.error("Error fetching popup data", error);
    }
  };

  const navigateToUrl = () => {
    setPopupState(false);
    // Optionally store the last shown time when navigating
    localStorage.setItem('popupLastShown', new Date().getTime());
    // Uncomment the line below to enable navigation
    // Open the URL in a new tab
    window.open(urlSet, '_blank');
  };

  const handleOverlayClick = (e) => {
    if (e.target.classList.contains("modal")) {
      setPopupState(false);
      // Store the last shown time when closed
      localStorage.setItem('popupLastShown', new Date().getTime());
    }
  };

  return (
    <div>
      <div
        className={`modal fade ${popupState ? "show d-block" : ""}`}
        tabIndex="-1"
        role="dialog"
        style={{ display: popupState ? "block" : "none", background: "rgba(0, 0, 0, 0.5)" }}
        onClick={handleOverlayClick}
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content" style={{ padding: 0 }}>
            <div
              className="modal-body"
              style={{
                backgroundImage: `url(${imgurl + popupImage})`,
                backgroundPosition: "center",
                backgroundSize: "cover",
                height: "400px",
                position: "relative",
              }}
            >
              <button
                type="button"
                className="close"
                style={{ position: "absolute", top: "10px", right: "10px", fontSize: "24px" }}
                onClick={() => {
                  setPopupState(false);
                  localStorage.setItem('popupLastShown', new Date().getTime());
                }}
              >
                &times;
              </button>
              <button
                className="btn btn-primary"
                style={{ borderColor: "#982876", backgroundColor: "#982876", position: "absolute", bottom: "20px", left: "50%", transform: "translateX(-50%)" }}
                onClick={navigateToUrl}
              >
               Contact Us
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Popup;
