import React from "react";

function Cart_empty() {



    alert("No Item In Your Cart")
}


export default Cart_empty;