export const ScrolltoTop = () => {
    window.scrollTo({
        top: 0,
        behavior: "smooth"
    });
}

export const dataConvert = (datestring) =>{
    const dateStr = datestring;
    const date = new Date(dateStr);
    
    // Get the day, month, and year from the date object
    const day = date.getUTCDate();
    const month = date.getUTCMonth() + 1; // getUTCMonth() returns a zero-based index
    const year = date.getUTCFullYear();
    
    // Convert month index to month name
    const monthNames = [
      "Jan", "Feb", "Mar", "Apr", "May", "Jun",
      "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
    ];
    const monthName = monthNames[month - 1];
    
    // Format the date in the desired format
    const formattedDate = `${day}, ${monthName} ${year}`;
    return formattedDate
    
}