// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("./fonts/BAHNSCHRIFT.TTF", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "\n@font-face {\n  font-family: \"BAHNSCHRIFT\";   /*Can be any text*/\n  src: local(\"BAHNSCHRIFT\"),\n    url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") format(\"truetype\");\n}\n\nbody {\n  margin: 0;\n   font-family: 'BAHNSCHRIFT';\n  -webkit-font-smoothing: antialiased;\n  -moz-osx-font-smoothing: grayscale;\n}\ncode {\n  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',\n    monospace;\n}\n", "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":";AACA;EACE,0BAA0B,IAAI,kBAAkB;EAChD;8DACmD;AACrD;;AAEA;EACE,SAAS;GACR,0BAA0B;EAC3B,mCAAmC;EACnC,kCAAkC;AACpC;AACA;EACE;aACW;AACb","sourcesContent":["\n@font-face {\n  font-family: \"BAHNSCHRIFT\";   /*Can be any text*/\n  src: local(\"BAHNSCHRIFT\"),\n    url(\"./fonts/BAHNSCHRIFT.TTF\") format(\"truetype\");\n}\n\nbody {\n  margin: 0;\n   font-family: 'BAHNSCHRIFT';\n  -webkit-font-smoothing: antialiased;\n  -moz-osx-font-smoothing: grayscale;\n}\ncode {\n  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',\n    monospace;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
