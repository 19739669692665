import "./styles/home.css";
import React, { Suspense, useEffect, useState } from "react";
import loadable from '@loadable/component'
import {
  category_list,
  festivebanner_settings,
} from "../data/API";
import { useNavigate, } from "react-router-dom";
// import Loader from "../Components/loader";
// import Testimonial from "../Components/testimonial";
import axios from "axios";
// import Topslider from "./MobileComponents/Home/Topslider";
// import Collections from "./MobileComponents/Home/Collection";
// import BottomBannerNew from "./MobileComponents/Home/BottomBanner";
// import ProductSlider from "./MobileComponents/Home/BestSellers";
// import BlogCard from "./Components/BlogCard";
// import Contactus from "./Components/Contactus";
import { ScrolltoTop } from "../utility";
// import FirstBanner from "../Components/FirstBanner";
const FirstBanner = loadable(() => import("../Components/FirstBanner"));
const Contactus = loadable(() => import("./Components/Contactus"));
const BlogCard = loadable(() => import("./Components/BlogCard"))
const ProductSlider = loadable(() => import("./MobileComponents/Home/BestSellers"));
const BottomBannerNew = loadable(() => import("./MobileComponents/Home/BottomBanner"));
const Collections = loadable(() => import("./MobileComponents/Home/Collection"));
const Topslider = loadable(() => import("./MobileComponents/Home/Topslider"));
const Testimonial = loadable(() => import("../Components/testimonial"));

const BannerSlide = loadable(() => import('../Components/BannerSlide'));




const baseurl = process.env.REACT_APP_BASE_URL;

const imgurl = process.env.REACT_APP_IMG_URL;

function Home() {
  let navigate = useNavigate();

  const [category, setCategory] = useState([]);
  const [youtube_ban, setYoutubeBanner] = useState([]);

  const [bannerSettings, setbannerSettings] = useState("");
  const [loader, isLoad] = useState(false);

  const category_list_fun = async () => {
    let response = await category_list();
    response && setCategory(response.data.data);
  };

  const godetails = (id) => {
    navigate("/blogdetails/" + id);
  };

  const bannerVisbleCheck = async () => {
    let response_return = await festivebanner_settings();
    let visible = await response_return.data.data[0].visible;

    setbannerSettings(visible);
  };

  const toggle = (index) => {
    if (index == 0) {
      return "active";
    }
    return "";
  };

  const [product_li, setProduct] = useState([]);
  const [bestSellers, setBestProduct] = useState([]);

  useEffect(() => {
    ScrolltoTop()
    category_list_fun().then(product_list_fun("Grand Series"));
    bannerVisbleCheck();
    bestSellersList();
    youtube_banner()
  }, [])


  const bestSellersList = async () => {
    let response = await axios
      .get(baseurl + "user/best_sellers")
      .then((result) => {
        setBestProduct(result.data.data);
      });
  };

  const youtube_banner = async () => {
    let response = await axios
      .get(baseurl + "user/youtube_banner")
      .then((result) => {
        setYoutubeBanner(result.data.data[0]);
      });
  };

  const product_list_fun = async (id) => {
    const requestBody = {
      category_id: id.replace(/-/g, " "),
    };

    let response = await axios
      .post(baseurl + "user/product_list", requestBody)
      .then((result) => {
        setProduct(result.data.data);
        setTimeout(() => {
          isLoad(false);
        }, 100);
      });
  };


  const redirect_Data = (url) => {
    const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
  }


  //TODO: CHANGE BODY TAG ID FOR CONTACT
  return (
    <>
      <div className="main-content">
        <div id="wrapper-site">
          <div id="content-wrapper" className="full-width">
            <div id="main">
              <section className="page-home">
                {bannerSettings === "Enable" ?
                  // <Suspense fallback={<p>Loading</p>}>
                    <BannerSlide />
                  // </Suspense>
                  : null}
                <div className="desktop-responisve" >
                  {/* <Suspense fallback={"loading..."}> */}

                    <FirstBanner />
                  {/* </Suspense> */}
                </div>
                <div className="mobile-responisve">
                  {/* <Suspense fallback={"loading..."}> */}

                    <Topslider />
                  {/* </Suspense> */}
                </div>
                <div className="highlight-banner-container" style={{ marginTop: 20 }}>
                  <div className="highlight-banner">
                    <div className="highlight-banner-block">
                      <div className="highlight-banner-icon">
                        <img
                          src={imgurl + "supremeQuality.png"}
                          alt="Supreme quality"
                          loading="lazy"
                        />
                      </div>
                      <div className="highlight-banner-text">
                        <h3 className="headingThird">Supreme Quality</h3>
                      </div>
                    </div>
                    <div className="highlight-banner-block">
                      <div className="highlight-banner-icon">

                        <img src={imgurl + "Earned.png"} alt="Earned Trust" loading="lazy" />
                      </div>
                      <div className="highlight-banner-text">
                        <h3 className="headingThird"> Earned Trust</h3>
                      </div>
                    </div>
                    <div className="highlight-banner-block">
                      <div className="highlight-banner-icon">
                        <img
                          loading="lazy"
                          src={imgurl + "Hygiene.png"}
                          alt="Hygiene Standards"
                        />
                      </div>
                      <div className="highlight-banner-text">
                        <h3 className="headingThird">Hygiene Standards</h3>
                      </div>
                    </div>
                    <div className="highlight-banner-block">
                      <div className="highlight-banner-icon">
                        <img src={imgurl + "india.png"} alt="Made in India" loading="lazy" />
                      </div>
                      <div className="highlight-banner-text">
                        <h3 className="headingThird"> Made in India</h3>
                      </div>
                    </div>
                    <div className="highlight-banner-block">
                      <div className="highlight-banner-icon">
                        <img
                          src={imgurl + "support.png"}
                          alt="Customer Support"
                          loading="lazy"
                        />
                      </div>
                      <div className="highlight-banner-text">
                        <h3 className="headingThird"> Support on Call</h3>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="tw-flex tw-flex-col tw-gap-5 tw-items-center tw-justify-center tw-py-10 tw-px-5 tw-bg-gray-100">
                  <h2 className="tw-px-[4rem] tw-text-[25px] xl:tw-text-[30px] md:tw-text-auto lg:tw-text-auto xl:tw-text-auto py-3 tw-bg-[#982876] tw-rounded-full tw-text-white tw-animate-bounce tw-text-center">Join Our 8,00,000+ Happy Customers</h2>
                  <p className="text-center tw-text-[16px] sm:tw-text-[18px] lg:tw-text-[20px] xl:tw-text-[20px] tw-capitalize">People's Trusted Mattress Brand since 2012 </p>
                </div>

                <div>
                  <center>
                    {" "}
                    {/* <Suspense fallback={"loading..."}> */}

                      <ProductSlider
                        detailsData={bestSellers}
                        title="Best Sellers"
                      />
                    {/* </Suspense> */}
                  </center>
                </div>
                <div className="mobile">
                  {" "}
                  {/* <Suspense fallback={"loading..."}> */}

                    <BottomBannerNew />
                  {/* </Suspense> */}
                  {" "}
                </div>

                <div style={{ marginTop: 75 }}>
                  {" "}
                  {/* <Suspense fallback={"loading..."}> */}

                    <Collections dataShare={youtube_ban && youtube_ban} />{" "}
                  {/* </Suspense> */}
                </div>

                <div style={{ paddingBottom: "50px", paddingTop: "50px", background: '#992876', marginTop: 55 }}>
                  {/* <Suspense fallback={"loading..."}> */}

                    <Contactus />
                  {/* </Suspense> */}
                </div>
                <div
                  className="demo-video desktop-responisve"
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    paddingTop: 80,
                  }}
                >

                  <img loading="lazy" onClick={() => { redirect_Data(youtube_ban && youtube_ban.youtube_url) }} width="90%" src={youtube_ban && imgurl + youtube_ban.youtube_image} style={{ cursor: "pointer" }} />
                </div>

              </section>
            </div>
          </div>
        </div>
        {/* <Loader /> */}
      </div>
      <div style={{ marginBottom: "50px", marginTop: "70px" }}>
        <Suspense fallback={"loading..."}>

          <Testimonial />
        </Suspense>
      </div>
      <div style={{ marginBottom: "20px", marginTop: 75 }}>
        <Suspense fallback={"loading..."}>

          <BlogCard />
        </Suspense>
      </div>
    </>
  );
}

export default Home;
