import React from "react";
import { useNavigate } from "react-router-dom";

function Sidebar() {

  let navigate = useNavigate();

  const warranty_page = () =>{
    navigate('/warranty');
  }

  const blog = () =>{
    navigate('/blog');
  }


  const about = () =>{
    navigate('/about');
  }


  const contact = () =>{
    navigate('/contact');
  }
  return (
    <div>
      <div className="wrap-banner">
        <div className="menu-banner d-xs-none">
          <div className="tiva-verticalmenu block" data-count_showmore="17">
            <div className="box-content block_content">
              <div className="verticalmenu" role="navigation">
                <ul className="menu level1">
                  <li className="item parent">
                  <a  style={{cursor:'pointer'}}className="hasicon" title="SIDE TABLE" onClick={about}>
                      <img src={require("../assets/img/home/bed.png")} alt="img" />
                      About Us
                    </a>
                   
                  </li>

                  <li className="item parent">
                  <a  style={{cursor:'pointer'}}className="hasicon" title="SIDE TABLE" onClick={contact}>
                      <img src={require("../assets/img/home/bed.png")} alt="img" />
                      Contact Us
                    </a>
                   
                  </li>

                
                  <li className="item parent">
               
                    <a  style={{cursor:'pointer'}}className="hasicon" title="SIDE TABLE" onClick={warranty_page}> 
                  
                      <img src={require("../assets/img/home/bed.png")} alt="img" />
                      Warranty
                    
                    </a>
                        
                   
                  </li>
              


                  <li className="item parent">
                  <a  style={{cursor:'pointer'}}className="hasicon" title="SIDE TABLE" onClick={blog}>

                      <img src={require("../assets/img/home/bed.png")} alt="img" />
                      Blog
                    </a>
                   
                  </li>

                   {/* <li className="item parent">
                  <a  style={{cursor:'pointer'}} className="hasicon" title="SIDE TABLE" href="https://admin.poppyindia.com/a_assets/catelog/Catalog.pdf" target="_blank" >

                      <img src={require("../assets/img/home/bed.png")} alt="img" />
                      Catelogue
                    </a>
                   
                  </li> */}

                 



                  {/* <li className="item parent group">
                    <a href="#" className="hasicon" title="FI">
                      <img src={require("../assets/img/home/bed.png")} alt="img" />
                      FIREPLACE
                    </a>
                  </li> */}
                  {/* <li className="item group-category-img parent group">
                    <a href="#" className="hasicon" title="TABLE LAMP">
                      <img src={require("../assets/img/home/bed.png")} alt="img" />
                      TABLE LAMP
                    </a>
                  </li> */}
                </ul>
              </div>
              <div className="d-flex justify-content-center align-items-center header-top-left pull-left">
                <div className="toggle-nav act">
                  <div className="btnov-lines-large">
                    <i className="zmdi zmdi-close"></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    
  );
}

export default Sidebar;



