const ScriptAdding = (script_cdn) =>{
     // Create a script element
     const script = document.createElement('script');
     script.src = script_cdn; // Replace with the script URL you want to load
     script.async = true; // Set to true if the script should be loaded asynchronously
 
     // Append the script element to the document's head
     document.head.appendChild(script);
 
     // Clean up by removing the script element when the component unmounts
     return () => {
       document.head.removeChild(script);
     };
}


module.exports = {
    ScriptAdding,
}