import React from 'react'
import logo from "../assets/img/logo.png";
import svgImage from "../assets/poppy-illustration.svg";


export default function Maintanance() {
    return (
        <section className="d-flex justify-content-center py-5 px-3 align-items-center flex-column mx-auto">
            <img src={logo} className="h-100" alt="Logo" style={{ maxHeight: '4rem' }} />
            <div className="text-center py-5">
                <h1 className="display-5 fw-bold">We'll Be Back Soon</h1>
                <p className="d-md-none small">
                    Our website is currently undergoing scheduled maintenance. We should be back shortly. Thank you for your patience.
                </p>
                <p className="d-none d-md-block">
                    Our website is currently undergoing scheduled maintenance. We should be back shortly. Thank you for your patience.
                </p>
            </div>
            <img src={svgImage} alt="Illustration" />
        </section>
    )
}
