import { Link, NavLink } from "react-router-dom";
import React, { useContext, useEffect, useState } from "react";
import { useHistory, useNavigate, useParams } from 'react-router-dom';

import { CartContext } from "../Context/Ecomcontext";
import Cart_empty from "./cartempty";
import Loader from "../Components/loader";
import cartSection from "../Helper/Cart";

const baseurl = process.env.REACT_APP_BASE_URL;


const imgurl = process.env.REACT_APP_IMG_URL;
function Cart() {
  let navigate = useNavigate();

  //  var base ="http://poppyindia.com/erp/assets/images/";

  var cart_data_demo = sessionStorage.getItem("poppy-cart");

  const [cart, setCart] = useContext(CartContext);

  const buy_func = () => {

    window.location.href = "https://poppyindia.com/checkout"

  };

  useEffect(() => {
    var cart_data = sessionStorage.getItem("poppy-cart");

    if (cart_data != null) {
      setCart(JSON.parse(cart_data));
    }
    if (cart !== '') {
      total = cart.reduce((prev, next) => parseInt(prev) + parseInt(next.amount), 0);
    } else {
      total = 0;
    }
  }, []);


  if (cart.length == 0) {

    return (
      <>
        <div className="product-cart checkout-cart blog">
          <div className="main-content" id="cart">
            <div id="wrapper-site">
              <nav className="breadcrumb-bg">
                <div className="container no-index">
                  <div className="breadcrumb">
                    <ol>
                      <li>
                        <a href="#">
                          <span>Home</span>
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          <span>Shopping Cart</span>
                        </a>
                      </li>
                    </ol>
                  </div>
                </div>
              </nav>
              <div className="container">
                <div className="row">
                  <div
                    id="content-wrapper"
                    className="col-xs-12 col-sm-12 col-md-12 col-lg-12 onecol"
                  >
                    <section id="main">
                      <div className="cart-grid row">
                        <div className="col-md-9 col-xs-12 check-info">
                          <h1 className="title-page">Shopping Cart</h1>
                          <div className="cart-container">
                            <div className="cart-overview js-cart">
                              <ul className="cart-items">
                                <li><center>< img src={"https://mehrallies.co.tz/shop/img/core-img/no_products_found.png"} /></center></li>
                              </ul>
                            </div>
                          </div>

                        </div>
                        <div className="cart-grid-right col-xs-12 col-lg-3 media_set">
                          <div className="cart-summary">
                            <div className="cart-detailed-totals">
                              <div className="cart-summary-products">
                                <div className="summary-label">
                                  There are 0 item in your cart
                                </div>
                              </div>
                              <div
                                className="cart-summary-line"
                                id="cart-subtotal-shipping"
                              >
                                <span className="label">Total Shipping : </span>
                                <span className="value"> Free</span>
                                <div>
                                  <small className="value"></small>
                                </div>
                              </div>
                              <div className="cart-summary-line cart-total">
                                <span className="label">Total : </span>
                                <span className="value"> ₹ 0</span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </section>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Loader />
        </div>
      </>
    );
  }

  const removeProduct = (id) => {
    setCart(cartSection.removeCart(id));
  }


  const addCart_update = (id, amt, type) => {
    //TODO METHOD ACTION FOR ADD




    setCart(cartSection.addCart_update(id, parseInt(amt), type));

  }
  if (cart !== null) {
    var total = cart.reduce((prev, next) => parseInt(prev) + parseInt(next.amount), 0);
  } else {
    total = 0;
  }

  // cart_data = [{"product_id":"20","amount":20904,"product_count":1,"title":"Selene T.T","image":"selene-tt-thumn.jpg","bed_type":"Single","dimension":"72X72","thickness":"6 inch"},{"product_id":"2","amount":null,"product_count":3,"title":"The Guardianz E.T","image":"thumbnails-GETB2.jpg","bed_type":"Single","dimension":"72X72","thickness":"6 inch"}];

  // alert(cart_data);


  return (
    <>
      <div className="product-cart checkout-cart blog">
        <div className="main-content" id="cart">
          <div id="wrapper-site">
            <nav className="breadcrumb-bg">
              <div className="container no-index">
                <div className="breadcrumb">
                  <ol>
                    <li>
                      <a href="#">
                        <span>Home</span>
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <span>Shopping Cart</span>
                      </a>
                    </li>
                  </ol>
                </div>
              </div>
            </nav>
            <div className="container">
              <div className="row">
                <div
                  id="content-wrapper"
                  className="col-xs-12 col-sm-12 col-md-12 col-lg-12 onecol"
                >
                  <section id="main">
                    <div className="cart-grid row">
                      <div className="col-md-9 col-xs-12 check-info">
                        <h1 className="title-page">Shopping Cart</h1>
                        <div className="cart-container">
                          <div className="cart-overview js-cart">
                            <ul className="cart-items">
                              {cart.length > 0 ? cart.map(ad => (
                                <li className="cart-item">
                                  <div className="product-line-grid row justify-content-between">
                                    <div className="product-line-grid-left col-md-2">
                                      <span className="product-image media-middle">
                                        <a >
                                          <img
                                            className="img-fluid"
                                            src={imgurl + ad.image}
                                            alt="No Image Available"
                                          />
                                        </a>
                                      </span>
                                    </div>
                                    <div className="product-line-grid-body col-md-6">
                                      <div className="product-line-info">
                                        <a
                                          className="label"
                                          href="product-detail.html"
                                          data-id_customization="0"
                                        >
                                          {ad.title}<br />
                                          {ad.sub_devision != 0 && <span style={{ color: "#982876", fontSize: 10 }}> {ad.sub_devision}</span>}
                                        </a>
                                      </div>
                                      <div className="product-line-info product-price">
                                        <span className="value">₹ {ad.amount / ad.product_count}</span>
                                      </div>
                                      <div className="product-line-info">
                                        <span className="label-atrr">Size:</span>
                                        <span className="value">{ad.bed_type}</span>
                                      </div>
                                      <div className="product-line-info">
                                        <span className="label-atrr">Dimension:</span>
                                        <span className="value">{ad.dimension}</span>
                                      </div>
                                      <div className="product-line-info">
                                        <span className="label-atrr">Thcikness:</span>
                                        <span className="value">{ad.thickness}</span>
                                      </div>
                                    </div>
                                    <div className="product-line-grid-right text-center product-line-actions col-md-4">
                                      <div className="row">
                                        <div className="col-md-5 col qty">
                                          <div>
                                          <div className="label">Qty:</div>
                                            <div className="tw-flex tw-gap-1">
                                              <input type="text" value={ad.product_count}  className=" tw-w-[30px] text-center tw-py-2 " style={{border:"1px solid silver"}} />
                                              <div className="tw-flex tw-flex-col justify-content-between tw-gap-1">
                                                <span onClick={() => addCart_update(ad.product_id, ad.amount / ad.product_count, "plus")} className="tw-px-2 tw-border-black/20 tw-bg-white tw-cursor-pointer active:tw-opacity-50" style={{border:"1px solid silver"}}>
                                                  +
                                                </span>
                                                <span onClick={() => addCart_update(ad.product_id, ad.amount / ad.product_count, "minus")} className="tw-px-2 tw-border-black tw-bg-white tw-cursor-pointer active:tw-opacity-50" style={{border:"1px solid silver"}}>
                                                  -
                                                </span>
                                              </div>
                                            </div>
                                          </div>

                                          {/* <div className="label">Qty:</div>
                                          <div className="quantity" >
                                            <input
                                              type="text"
                                              name="qty"
                                              value={ad.product_count}
                                              className="input-group form-control"
                                            />

                                            <span className="input-group-btn-vertical">
                                              <button
                                                className="btn btn-touchspin js-touchspin bootstrap-touchspin-up"
                                                type="button"
                                                onClick={() => addCart_update(ad.product_id, ad.amount / ad.product_count, "plus")}
                                              >
                                                +
                                              </button>
                                              <button
                                                className="btn btn-touchspin js-touchspin bootstrap-touchspin-down"
                                                type="button"
                                                onClick={() => addCart_update(ad.product_id, ad.amount / ad.product_count, "minus")}
                                              >
                                                -
                                              </button>
                                            </span>
                                          </div> */}
                                        </div>
                                        <div className="col-md-5 col price">
                                          <div className="label">Total:</div>
                                          <div className="product-price total">
                                            ₹ {parseInt(ad.amount)}
                                          </div>
                                        </div>
                                        <div className="col-md-2 col text-xs-right align-self-end">
                                          <div className="cart-line-product-actions ">
                                            <a
                                              className="remove-from-cart"
                                              rel="nofollow"
                                              href="#"
                                              data-link-action="delete-from-cart"
                                              data-id-product="1"
                                              onClick={() => removeProduct(ad.product_id)}
                                              style={{ pointer: "cursor" }}
                                            >
                                              <i
                                                className="fa fa-trash-o"
                                                aria-hidden="true"
                                              ></i>
                                            </a>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </li>
                              )) : ""}



                            </ul>
                          </div>

                        <div className="tw-flex tw-gap-5">
                          <a href="/checkout" className="tw-text-[15px] tw-text-white tw-bg-black tw-px-5 tw-py-3 tw-rounded-full tw-capitalize hover:tw-text-white">checkout</a>
                          <a href="/productlist/all" className="tw-text-[15px] tw-text-white tw-bg-[#982876] tw-px-5 tw-py-3 tw-rounded-full tw-capitalize hover:tw-text-white">continue shopping</a>

                        </div>

                        </div>
                        {/* <div className="tw-flex tw-gap-5" hidden>

                        <a
                          href="/checkout"
                          className="continue btn btn-primary pull-xs-right tw-px-20"
                        >
                          Checkout
                        </a>
                        <a
                          href="/checkout"
                          className="continue btn btn-primary pull-xs-right tw-text-[20px]"
                        >
                          Continue Shopping
                        </a>
                        </div> */}
                      </div>
                      <div className="cart-grid-right col-xs-12 col-lg-3 media_set" >
                        <div className="cart-summary">
                          <div className="cart-detailed-totals">
                            <div className="cart-summary-products">
                              <div className="summary-label">
                                There are {cart.length < 0 ? 0 : cart.length} item in your cart
                              </div>
                            </div>
                            {/* <div
                          className="cart-summary-line"
                          id="cart-subtotal-products"
                        >
                          <span className="label js-subtotal">Total products:</span>
                          <span className="value">£200.00</span>
                        </div> */}
                            <div
                              className="cart-summary-line"
                              id="cart-subtotal-shipping"
                            >
                              <span className="label">Total Shipping : </span>
                              <span className="value"> Free</span>
                              <div>
                                <small className="value"></small>
                              </div>
                            </div>
                            <div className="cart-summary-line cart-total">
                              <span className="label">Total : </span>
                              <span className="value"> ₹ {parseInt(total)}</span>
                            </div>
                          </div>
                        </div>
                        {/* <div id="block-reassurance">
                      <ul>
                        <li>
                          <div className="block-reassurance-item">
                            <img
                              src={require("../assets/img/product/check1.png")}
                              alt="Security policy (edit with Customer reassurance module)"
                            />
                            <span>
                              Security policy (edit with Customer reassurance
                              module)
                            </span>
                          </div>
                        </li>
                        <li>
                          <div className="block-reassurance-item">
                            <img
                              src={require("../assets/img/product/check2.png")}
                              alt="Delivery policy (edit with Customer reassurance module)"
                            />
                            <span>
                              Delivery policy (edit with Customer reassurance
                              module)
                            </span>
                          </div>
                        </li>
                        <li>
                          <div className="block-reassurance-item">
                            <img
                              src={require("../assets/img/product/check3.png")}
                              alt="Return policy (edit with Customer reassurance module)"
                            />
                            <span>
                              Return policy (edit with Customer reassurance
                              module)
                            </span>
                          </div>
                        </li>
                      </ul>
                    </div> */}
                      </div>
                    </div>
                  </section>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Loader />
      </div>
    </>
  );
}

export default Cart;
