import React, { useContext } from 'react'
import { Link } from 'react-router-dom';
import { CommonContext } from '../../Context/CommonContext';

export default function SideMenu() {

    const { sideMenuModal, setsideMenuModal } = useContext(CommonContext)
    var userData = "";

    if (localStorage.getItem('userInfo') != null) {
        userData = JSON.parse(localStorage.getItem('userInfo'));
    }
    else {
        userData = "";
    }

    const data = [
        {
            id: 1,
            title: 'Mattress',
            link: '/productlist/all',
            icon: <BedIcon width={30} height={30} />,
            mobileonly: true,
            logincheck: false,

        },
        {
            id: 1,
            title: 'Accessories',
            link: '/accessories/all',
            icon: <Pillow width={30} height={30} />,
            mobileonly: true,
            logincheck: false,

        },
        {
            id: 1,
            title: 'about us',
            link: '/about',
            icon: <About width={30} height={30} />,
            mobileonly: false
        },
        {
            id: 1,
            title: 'contact us',
            link: '/contact',
            icon: <Contact width={30} height={30} />,
            mobileonly: false
        },
        {
            id: 1,
            title: 'warranty',
            link: '/warranty',
            icon: <Warranty width={30} height={30} />,
            mobileonly: false
        },
        {
            id: 1,
            title: 'blog',
            link: '/blog',
            icon: <Blog width={30} height={30} />,
            mobileonly: false
        },
        {
            id: 1,
            title: 'Wishlist',
            link: '/list',
            icon: <Heart width={30} height={30} />,
            mobileonly: true,
            logincheck: false,

        },
        // {
        //     id: 1,
        //     title: 'Signup',
        //     link: '/register',
        //     icon: <SignUp width={30} height={30} />,
        //     mobileonly: true,
        //     logincheck: true,

        // },
        // {
        //     id: 1,
        //     title: 'Signin',
        //     link: '/login',
        //     icon: <Login width={30} height={30} />,
        //     mobileonly: true,
        //     logincheck: true,

        // },
        // {
        //     id: 1,
        //     title: 'logout',
        //     link: '/login',
        //     icon: <Logout width={30} height={30} />,
        //     mobileonly: true,
        //     logincheck: true,

        // },
    ]

    return (
        <>
            <div className={`${sideMenuModal ? 'tw-fixed' : '-tw-translate-x-[200%]'} tw-w-[200px] tw-fixed tw-top-0 tw-bg-white tw-z-50 tw-h-screen tw-shadow-xl tw-transition-all tw-duration-500`}>
                <div className='tw-flex md:tw-hidden lg:tw-hidden xl:tw-hidden tw-p-2 justify-content-end'>
                    <CloseBlack onClick={() => setsideMenuModal(!sideMenuModal)} width={18} height={18} />
                </div>
                <ul className=''>
                    {data.map((item, index) =>


                        <li key={index} style={{ borderBottom: '1px solid #d9d3d3' }} className={`tw-opacity-60 ${item.mobileonly ? 'tw-block md:tw-hidden lg:tw-hidden xl:tw-hidden' : 'block'}`}>
                            <Link to={item.link} onClick={() => setsideMenuModal(!sideMenuModal)} className="tw-flex tw-capitalize tw-items-center tw-gap-3 active:tw-opacity-50 tw-cursor-pointer tw-p-3" title="" >
                                {item.icon}
                                {item.title}
                            </Link>
                        </li>

                    )}
                    <li key={56} style={{ borderBottom: '1px solid #d9d3d3' }} className={`tw-opacity-60 ${true ? 'tw-block md:tw-hidden lg:tw-hidden xl:tw-hidden' : 'block'} ${userData !== '' ? 'tw-hidden':''}`}>
                        <Link to='/register' onClick={() => setsideMenuModal(!sideMenuModal)} className="tw-flex tw-capitalize tw-items-center tw-gap-3 active:tw-opacity-50 tw-cursor-pointer tw-p-3" title="" >
                            <SignUp width={30} height={30} />
                            Sign up
                        </Link>
                    </li>
                    <li key={57} style={{ borderBottom: '1px solid #d9d3d3' }} className={`tw-opacity-60 ${true ? 'tw-block md:tw-hidden lg:tw-hidden xl:tw-hidden' : 'block'} ${userData !== '' ? 'tw-hidden':''} `}>
                        <Link to='/login' onClick={() => setsideMenuModal(!sideMenuModal)} className="tw-flex tw-capitalize tw-items-center tw-gap-3 active:tw-opacity-50 tw-cursor-pointer tw-p-3" title="" >
                            <Login width={30} height={30} />
                            Login
                        </Link>
                    </li>
                    <li key={58} style={{ borderBottom: '1px solid #d9d3d3' }} className={`tw-opacity-60 ${true ? 'tw-block md:tw-hidden lg:tw-hidden xl:tw-hidden' : 'block'} ${userData !== '' ? '':'tw-hidden'} `}>
                        <Link to={'/login'} onClick={() => {localStorage.removeItem("userInfo"); setsideMenuModal(!sideMenuModal)}} className="tw-flex tw-capitalize tw-items-center tw-gap-3 active:tw-opacity-50 tw-cursor-pointer tw-p-3" title="" >
                            <Logout width={30} height={30} />
                            Log out
                        </Link>
                    </li>
                </ul>
                <div onClick={() => setsideMenuModal(!sideMenuModal)} className='tw-hidden md:tw-flex lg:tw-flex xl:tw-flex tw-absolute tw-w-[100px] tw-h-[100px] active:tw-opacity-40 tw-cursor-pointer tw-bg-black tw-text-white tw-top-0 -tw-right-[100px]  tw-justify-center tw-items-center'>
                    <Close width={20} height={20} className="!tw-text-white" />
                </div>
            </div>
        </>
    )
}


function BedIcon(props) {
    return <svg stroke="currentColor" fill="currentColor" strokeWidth={0} viewBox="0 0 512 512" height="1em" width="1em" {...props}><path d="M458.2 208h-.4c-12 0-21.8 9.8-21.8 21.8 0 1.2-1 2.2-2.2 2.2H78.2c-1.2 0-2.2-1-2.2-2.2 0-12-9.8-21.8-21.8-21.8h-.4c-12 0-21.8 9.8-21.8 21.8v180.4c0 12 9.8 21.8 21.8 21.8h.4c12 0 21.8-9.8 21.8-21.8V404c0-2.2 1.8-4 4-4h352c2.2 0 4 1.8 4 4v6.2c0 12 9.8 21.8 21.8 21.8h.4c12 0 21.8-9.8 21.8-21.8V229.8c0-12-9.8-21.8-21.8-21.8z" /><path d="M84 214h22.5c2.2 0 4-1.8 4-4v-13c0-8.8 7.2-16 16-16H224c8.8 0 16 7.2 16 16v13c0 2.2 1.8 4 4 4h24c2.2 0 4-1.8 4-4v-13c0-8.8 7.2-16 16-16h97.5c8.8 0 16 7.2 16 16v13c0 2.2 1.8 4 4 4H428c4.4 0 8-3.6 8-8v-94c0-17.6-14.4-32-32-32H108c-17.6 0-32 14.4-32 32v94c0 4.4 3.6 8 8 8z" /></svg>;
}


function Close(props) {
    return <svg stroke="currentColor" fill="currentColor" strokeWidth={0} viewBox="0 0 24 24" height="1em" width="1em" {...props}><path fill="none" stroke="#ffffff" strokeWidth={3} d="M3,3 L21,21 M3,21 L21,3" /></svg>;
}

function CloseBlack(props) {
    return <svg stroke="currentColor" fill="currentColor" strokeWidth={0} viewBox="0 0 24 24" height="1em" width="1em" {...props}><path fill="none" stroke="#000000" strokeWidth={3} d="M3,3 L21,21 M3,21 L21,3" /></svg>;
}


function Login(props) {
    return <svg stroke="currentColor" fill="currentColor" strokeWidth={0} viewBox="0 0 1024 1024" height="1em" width="1em" {...props}><defs /><path d="M521.7 82c-152.5-.4-286.7 78.5-363.4 197.7-3.4 5.3.4 12.3 6.7 12.3h70.3c4.8 0 9.3-2.1 12.3-5.8 7-8.5 14.5-16.7 22.4-24.5 32.6-32.5 70.5-58.1 112.7-75.9 43.6-18.4 90-27.8 137.9-27.8 47.9 0 94.3 9.3 137.9 27.8 42.2 17.8 80.1 43.4 112.7 75.9 32.6 32.5 58.1 70.4 76 112.5C865.7 417.8 875 464.1 875 512c0 47.9-9.4 94.2-27.8 137.8-17.8 42.1-43.4 80-76 112.5s-70.5 58.1-112.7 75.9A352.8 352.8 0 0 1 520.6 866c-47.9 0-94.3-9.4-137.9-27.8A353.84 353.84 0 0 1 270 762.3c-7.9-7.9-15.3-16.1-22.4-24.5-3-3.7-7.6-5.8-12.3-5.8H165c-6.3 0-10.2 7-6.7 12.3C234.9 863.2 368.5 942 520.6 942c236.2 0 428-190.1 430.4-425.6C953.4 277.1 761.3 82.6 521.7 82zM395.02 624v-76h-314c-4.4 0-8-3.6-8-8v-56c0-4.4 3.6-8 8-8h314v-76c0-6.7 7.8-10.5 13-6.3l141.9 112a8 8 0 0 1 0 12.6l-141.9 112c-5.2 4.1-13 .4-13-6.3z" /></svg>;
}


function Logout(props) {
    return <svg stroke="currentColor" fill="currentColor" strokeWidth={0} viewBox="0 0 1024 1024" height="1em" width="1em" {...props}><path d="M868 732h-70.3c-4.8 0-9.3 2.1-12.3 5.8-7 8.5-14.5 16.7-22.4 24.5a353.84 353.84 0 0 1-112.7 75.9A352.8 352.8 0 0 1 512.4 866c-47.9 0-94.3-9.4-137.9-27.8a353.84 353.84 0 0 1-112.7-75.9 353.28 353.28 0 0 1-76-112.5C167.3 606.2 158 559.9 158 512s9.4-94.2 27.8-137.8c17.8-42.1 43.4-80 76-112.5s70.5-58.1 112.7-75.9c43.6-18.4 90-27.8 137.9-27.8 47.9 0 94.3 9.3 137.9 27.8 42.2 17.8 80.1 43.4 112.7 75.9 7.9 7.9 15.3 16.1 22.4 24.5 3 3.7 7.6 5.8 12.3 5.8H868c6.3 0 10.2-7 6.7-12.3C798 160.5 663.8 81.6 511.3 82 271.7 82.6 79.6 277.1 82 516.4 84.4 751.9 276.2 942 512.4 942c152.1 0 285.7-78.8 362.3-197.7 3.4-5.3-.4-12.3-6.7-12.3zm88.9-226.3L815 393.7c-5.3-4.2-13-.4-13 6.3v76H488c-4.4 0-8 3.6-8 8v56c0 4.4 3.6 8 8 8h314v76c0 6.7 7.8 10.5 13 6.3l141.9-112a8 8 0 0 0 0-12.6z" /></svg>;
}


function Contact(props) {
    return <svg stroke="currentColor" fill="currentColor" strokeWidth={0} viewBox="0 0 24 24" height="1em" width="1em" {...props}><path d="M20,12v-1.707c0-4.442-3.479-8.161-7.755-8.29c-2.204-0.051-4.251,0.736-5.816,2.256C4.862,5.779,4,7.818,4,10v2 c-1.103,0-2,0.897-2,2v4c0,1.103,0.897,2,2,2h2V10c0-1.637,0.646-3.166,1.821-4.306c1.174-1.14,2.735-1.739,4.363-1.691 C15.392,4.099,18,6.921,18,10.293V20h2c1.103,0,2-0.897,2-2v-4C22,12.897,21.103,12,20,12z" /><path d="M7 12H9V20H7zM15 12H17V20H15z" /></svg>;
}


function About(props) {
    return <svg stroke="currentColor" fill="currentColor" strokeWidth={0} viewBox="0 0 24 24" height="1em" width="1em" {...props}><path d="M19,2H9C7.897,2,7,2.897,7,4v6H5c-1.103,0-2,0.897-2,2v9c0,0.552,0.447,1,1,1h8h8c0.553,0,1-0.448,1-1V4 C21,2.897,20.103,2,19,2z M5,12h3h3v2v2v4H5V12z M19,20h-6v-4v-2v-2c0-1.103-0.897-2-2-2H9V4h10V20z" /><path d="M11 6H13V8H11zM15 6H17V8H15zM15 10.031H17V12H15zM15 14H17V16H15zM7 14.001H9V16.000999999999998H7z" /></svg>;
}


function Pillow(props) {
    return <svg stroke="currentColor" fill="currentColor" strokeWidth={0} viewBox="0 0 512 512" height="1em" width="1em" {...props}><path d="M433.79 59.602c-8.94.047-20.654 3.077-34.966 9.876C295.2 62.252 170.14 77.352 86.788 103.535c-60.407-27.913-70.404-.426-42.95 36.186C10.242 239.454 24.97 340.803 60.7 413.31c-11.01 47.633 16.965 45.498 41.172 26.452 170.77 9.1 281.83-16.52 349.88-68.666 24.01 19.23 41.66-6.006 31.868-36.856 11.922-71.81-9.07-167.31-36.093-233.78 13.362-23.473 8.022-40.975-13.737-40.858zm-357.386 77.96c52.65 61.045 53.612 200.38 21.748 283.403 7.046-90.91 2.505-223.673-21.748-283.404z" /></svg>;
}



function Warranty(props) {
    return <svg stroke="currentColor" fill="currentColor" strokeWidth={0} viewBox="0 0 24 24" height="1em" width="1em" {...props}><path d="M19,4h-3V2h-2v2h-4V2H8v2H5C3.897,4,3,4.897,3,6v14c0,1.103,0.897,2,2,2h14c1.103,0,2-0.897,2-2V6C21,4.897,20.103,4,19,4z M5,20V7h3h2h4h2h3V6l0.002,14H5z" /><path d="M7 9H17V11H7zM7 13H12V15H7z" /></svg>;
}


function Blog(props) {
    return <svg stroke="currentColor" fill="currentColor" strokeWidth={0} viewBox="0 0 448 512" height="1em" width="1em" {...props}><path d="M162.4 196c4.8-4.9 6.2-5.1 36.4-5.1 27.2 0 28.1.1 32.1 2.1 5.8 2.9 8.3 7 8.3 13.6 0 5.9-2.4 10-7.6 13.4-2.8 1.8-4.5 1.9-31.1 2.1-16.4.1-29.5-.2-31.5-.8-10.3-2.9-14.1-17.7-6.6-25.3zm61.4 94.5c-53.9 0-55.8.2-60.2 4.1-3.5 3.1-5.7 9.4-5.1 13.9.7 4.7 4.8 10.1 9.2 12 2.2 1 14.1 1.7 56.3 1.2l47.9-.6 9.2-1.5c9-5.1 10.5-17.4 3.1-24.4-5.3-4.7-5-4.7-60.4-4.7zm223.4 130.1c-3.5 28.4-23 50.4-51.1 57.5-7.2 1.8-9.7 1.9-172.9 1.8-157.8 0-165.9-.1-172-1.8-8.4-2.2-15.6-5.5-22.3-10-5.6-3.8-13.9-11.8-17-16.4-3.8-5.6-8.2-15.3-10-22C.1 423 0 420.3 0 256.3 0 93.2 0 89.7 1.8 82.6 8.1 57.9 27.7 39 53 33.4c7.3-1.6 332.1-1.9 340-.3 21.2 4.3 37.9 17.1 47.6 36.4 7.7 15.3 7-1.5 7.3 180.6.2 115.8 0 164.5-.7 170.5zm-85.4-185.2c-1.1-5-4.2-9.6-7.7-11.5-1.1-.6-8-1.3-15.5-1.7-12.4-.6-13.8-.8-17.8-3.1-6.2-3.6-7.9-7.6-8-18.3 0-20.4-8.5-39.4-25.3-56.5-12-12.2-25.3-20.5-40.6-25.1-3.6-1.1-11.8-1.5-39.2-1.8-42.9-.5-52.5.4-67.1 6.2-27 10.7-46.3 33.4-53.4 62.4-1.3 5.4-1.6 14.2-1.9 64.3-.4 62.8 0 72.1 4 84.5 9.7 30.7 37.1 53.4 64.6 58.4 9.2 1.7 122.2 2.1 133.7.5 20.1-2.7 35.9-10.8 50.7-25.9 10.7-10.9 17.4-22.8 21.8-38.5 3.2-10.9 2.9-88.4 1.7-93.9z" /></svg>;
}


function Heart(props) {
    return <svg stroke="currentColor" fill="currentColor" strokeWidth={0} viewBox="0 0 1024 1024" height="1em" width="1em" {...props}><path d="M923 283.6a260.04 260.04 0 0 0-56.9-82.8 264.4 264.4 0 0 0-84-55.5A265.34 265.34 0 0 0 679.7 125c-49.3 0-97.4 13.5-139.2 39-10 6.1-19.5 12.8-28.5 20.1-9-7.3-18.5-14-28.5-20.1-41.8-25.5-89.9-39-139.2-39-35.5 0-69.9 6.8-102.4 20.3-31.4 13-59.7 31.7-84 55.5a258.44 258.44 0 0 0-56.9 82.8c-13.9 32.3-21 66.6-21 101.9 0 33.3 6.8 68 20.3 103.3 11.3 29.5 27.5 60.1 48.2 91 32.8 48.9 77.9 99.9 133.9 151.6 92.8 85.7 184.7 144.9 188.6 147.3l23.7 15.2c10.5 6.7 24 6.7 34.5 0l23.7-15.2c3.9-2.5 95.7-61.6 188.6-147.3 56-51.7 101.1-102.7 133.9-151.6 20.7-30.9 37-61.5 48.2-91 13.5-35.3 20.3-70 20.3-103.3.1-35.3-7-69.6-20.9-101.9z" /></svg>;
}


function SignUp(props) {
    return <svg stroke="currentColor" fill="currentColor" strokeWidth={0} viewBox="0 0 448 512" height="1em" width="1em" {...props}><path d="M224 256c70.7 0 128-57.3 128-128S294.7 0 224 0 96 57.3 96 128s57.3 128 128 128zm89.6 32h-16.7c-22.2 10.2-46.9 16-72.9 16s-50.6-5.8-72.9-16h-16.7C60.2 288 0 348.2 0 422.4V464c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48v-41.6c0-74.2-60.2-134.4-134.4-134.4z" /></svg>;
}
