import React from "react";
import c_head_img from "../assets/img/contact/contact_mess.png";
import btn_image from "../assets/img/contact/contact_email.png";
import Loader from "../Components/loader";
import Header from "../Components/header";
import Footer from "../Components/footer";
import {toast} from "react-toastify";
export default function Contact() {

  toast.configure();
  
    
  const toggleButton = () => {
    //TODO METHOD ACTION FOR ADD
    toast.success("Thanks For Contacting Us!.");
    
    
      
  }
  return (
    <>
    <div id="contact">
    <div className="main-content">
      <div id="wrapper-site">
        <div id="content-wrapper">
          <nav className="breadcrumb-bg">
            <div className="container no-index">
              <div className="breadcrumb">
                <ol>
                  <li>
                    <a href="#">
                      <span>Home</span>
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <span>Contact</span>
                    </a>
                  </li>
                </ol>
              </div>
            </div>
          </nav>
          <div id="main">
            <div className="page-home">
              <div className="container">
                <h1 className="text-center title-page">Contact Us</h1>
                <div className="row-inhert">
                  <div className="header-contact">
                    <div className="row">
                      <div className="col-xs-12 col-sm-4 col-md-4">
                        <div className="item d-flex">
                          <div className="item-left">
                            <div className="icon">
                              <i className="zmdi zmdi-email"></i>
                            </div>
                          </div>
                          <div className="item-right d-flex">
                            <div className="title" style={{color:'rgb(52 52 52)'}}>Email:</div>
                            <div className="contact-content">
                              <a href="mailto:support@poppyindia.com">
                                support@poppyindia.com
                              </a>
                              <br />
                              <a href="mailto:info@poppyindia.com">
                                info@poppyindia.com
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-xs-12 col-sm-4 col-md-4">
                        <div className="item d-flex">
                          <div className="item-left">
                            <div className="icon">
                              <i className="zmdi zmdi-home"></i>
                            </div>
                          </div>
                          <div className="item-right d-flex">
                                <div className="title" style={{color:'rgb(52 52 52)'}}>Address:</div>
                            <div className="contact-content">
                              S.F.No. 283/1B, Sukkampatti (Manalmedu), Thalapatti (P.O.),
                              <br />
                             Manmangalam (Tk), Karur - 639003, Tamil Nadu, India
                            </div>
                          </div>
                        </div> .
                      </div>
                      <div className="col-xs-12 col-sm-4 col-md-4">
                        <div className="item d-flex justify-content-end  last">
                          <div className="item-left">
                            <div className="icon">
                              <i className="zmdi zmdi-phone"></i>
                            </div>
                          </div>
                          <div className="item-right d-flex">
                                <div className="title" style={{color:'rgb(52 52 52)'}}>Hotline:</div>
                            <div className="contact-content">
                              +91 90548 48481
                              {/* <br />
                              0987-654-32100 */}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="contact-map">
                        <div id="map">
                          
                         
                      <iframe
                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3917.881648092037!2d78.01594971472247!3d10.896598259943888!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3baa2f683e5debd1%3A0x27d24cb36c1afd3b!2sPoppy%20Mattress%20Pvt%20Ltd!5e0!3m2!1sen!2sin!4v1653974879290!5m2!1sen!2sin"
                        allowfullscreen
                      ></iframe>
                    </div>
                  </div>
                  <div className="input-contact">
                    {/* <p className="text-intro text-center">
                      “Proin gravida nibh vel velit auctor aliquet. Aenean
                      sollicudin, lorem quis bibendum auctor, nisi elit
                      consequat ipsum, nec sagittis sem nibh id elit. Duis sed
                      odio sit amet nibh vultate cursus a sit amet mauris. Proin
                      gravida nibh vel velit auctor aliquet.”
                    </p> */}

                    <p className="icon text-center">
                      <a href="#">
                        <img src={c_head_img} alt="img" />
                      </a>
                    </p>

                    <div className="d-flex justify-content-center">
                      <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                        <div className="contact-form">
                          {/* <form
                            action="#"
                            method="post"
                            enctype="multipart/form-data"
                          > */}
                            <div className="form-fields">
                              <div className="form-group row">
                                <div className="col-md-6">
                                  <input
                                    className="form-control"
                                    name="name"
                                    placeholder="Your name"
                                  />
                                </div>
                                <div className="col-md-6 margin-bottom-mobie">
                                  <input
                                    className="form-control"
                                    name="from"
                                    type="email"
                                    value=""
                                    placeholder="Your email"
                                  />
                                </div>
                              </div>
                              <div className="form-group row">
                                <div className="col-md-12 margin-bottom-mobie">
                                  <input
                                    className="form-control"
                                    name="from"
                                    type="email"
                                    value=""
                                    placeholder="Subject"
                                  />
                                </div>
                              </div>
                              <div className="form-group row">
                                <div className="col-md-12">
                                  <textarea
                                    className="form-control"
                                    name="message"
                                    placeholder="Message"
                                    rows="8"
                                  ></textarea>
                                </div>
                              </div>
                            </div>
                            <div>
                              <button
                                className="btn"
                                type="submit"
                                name="submitMessage"
                             onClick={() =>{
        toggleButton()
      }} >
                                <img className="img-fl" src={btn_image} alt="img" />
                                Send message
                              </button>
                            </div>
                          {/* </form> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Loader />
    </div>
    </>
  );
}
