import "./styles/ProductDetail.css";

import { Default_discount, percentageCalculation } from "../Helper/Common";
import React, { useContext, useEffect, useState } from "react";
import { useHistory, useNavigate, useParams } from 'react-router-dom';

import { CartContext } from "../Context/Ecomcontext";
import Loader from "../Components/loader";
import Related_product from "../Components/related_products";
import axios from 'axios';
import cartSection from "../Helper/Cart";
import {toast} from "react-toastify";
import CoupenPng from "./MobileComponents/images/badge.png";
import { ScrolltoTop } from "../utility";

const imgurl = process.env.REACT_APP_IMG_URL;
const  baseurl = process.env.REACT_APP_BASE_URL;

const userData =  JSON.parse(localStorage.getItem('userInfo'));


export default function AccessoriesDetail() {



  //Image variables 



  let navigate = useNavigate();
 
  toast.configure();

//Customer Review 

const [name,set_name]=useState("");

const [email,set_email]=useState("");

const [comments,set_comments]=useState("");

//End Customer



//Function For Insert Comments

const customer_review = () => {

  if(name==="" || email==="" || comments==="")
  {
    toast.error("Please Fill All the Fields");
  }
  else
  {
    
  const requestBody = {
      
    name: name,
    email: email,
    comments:comments,
    product_id: id 
 
  };
  
  
  
  const result_data =  axios
  
  .post(baseurl + "user/product_review/", requestBody)
  
  .then(customer_review_list_func())
  toast.success("Thanks For Your Review!");
    document.getElementById("review-form").reset();
    set_name("");
    set_email("");
    set_comments(""); 
}
};

  const [cart, setCart] = useContext(CartContext);  
  const [price,set_price]=useState(0);
  const [coupen_code,set_coupen]=useState(0);
  const [new_dbcoupen,set_dbcoupen]=useState("");
  const [new_coupen,set_newcoupen]=useState(0);
  const [coupenDis,set_coupenDis]=useState(0);
  const [coupenDisPer,set_coupenDisPer]=useState(0);
  const [size, set_size] = useState({});
  const [singlesize, set_singlesize] = useState("");
  const [Product_Details, set_product_Details] = useState({});
  const [customer_review_list, set_cus_list] = useState({});
  //Image Section 
  const [image1,set_image1]=useState("pr10.jpg");
  const [image2,set_image2]=useState("pr10.jpg");
  const [image3,set_image3]=useState("pr10.jpg");
  const [image4,set_image4]=useState("pr10.jpg");
  const [bot_img, setbot_img] = useState("pr10.jpg");
  const [top_img, settop_img] = useState("pr10.jpg");
  const [description_image,setdescriptionimage]=useState("pr10.jpg");
  const [id,setId]=useState(null);
  const { acc_name } = useParams();  


  useEffect(() => {
    ScrolltoTop()
}, [])

  useEffect(() => {
     
        product_id_get(getProductById);
  }, [id]);


    const product_id_get= async (callback) =>{

 const requestBody = {
      
      product_name: acc_name.replace(/-/g, ' '),
 
      
    };
 

 const result_data = await axios
    
    .post(baseurl + "user/product_id_acc/", requestBody)
    .then(function (response) {

      console.log(response.data.data[0].id)

setId(response.data.data[0].id)
// setcategory_name(response.data.data[0].category_name)
callback();
    })



  }


  const errorPage = () =>{
    //TODO NAVIGATE TO ERROR PAGE
    navigate('/error');
  }  
const copenApply = () =>{
if(new_dbcoupen == new_coupen)
{
  toast.success("Coupon Applied");
   const discount_less=percentageCalculation(share,coupenDisPer);
   set_coupenDis(discount_less)
}
else
{
  toast.error("Invalide Coupon Code");
}
  }

  useEffect(() => {
    
    customer_review_list_func();
    couoncode_get();
    
  },[]);



  const customer_review_list_func = async () => {

    const requestBody = {
      product_id: id,  
    };
    const result_data = await axios
    .post(baseurl + "user/product_review_list/", requestBody)
    .then(function (response) {
      //TO SET PRODUCT BED TYPE 
      set_cus_list(response.data.data)
    })
    
  }

  
    const couoncode_get = async () => {
    const requestBody = { 
      c_for: "Accessories",
    };

    const result_data = await axios
    
    .post(baseurl + "user/coupon_get/", requestBody)
    
    .then(function (response) {
      
      //TO SET PRODUCT BED TYPE 
      
      set_dbcoupen(response.data.data[0].coupen_code)
      set_coupenDisPer(response.data.data[0].discount_percentage)
    })
    
  }
  
  const getProductById = async () => {
    const requestBody = {
      product_name: acc_name.replace(/-/g, ' '),
      product_id: id,
    };
    const result_data = await axios.post(baseurl + "user/accessories_details/", requestBody).then(function (response) {
      
      set_size(response.data.product_specs);
      
      set_singlesize(response.data.product_specs[0].size)
      set_price(response.data.product_specs[0].price)
     
      //TO SET PRODUCT DETAILS 
      
      set_product_Details(response.data.product_data[0]);
      set_image1(response.data.product_data[0].image1);
      set_image2(response.data.product_data[0].image2);
      set_image3(response.data.product_data[0].image3);
      set_image4(response.data.product_data[0].image4);  
      settop_img(response.data.product_data[0].description_top_image);
      setbot_img(response.data.product_data[0].description_bottom_image);
      setdescriptionimage(response.data.product_data[0].description_main_image);
      //TO SET PRODUCT DETAILS 

      set_coupen(response.data.product_data[0].discount)
      
    })
    
  }

    const getProduct_price = async (singlesize) => {

 
    const requestBody = {
      
      product_id: id,
      size: singlesize ,

    };
    
   
    
    
    const result_data = await axios
    .post(baseurl + "user/acc_price_data/", requestBody)
    .then(function (response) {
      
     
     
     set_price(response.data.data[0].price)
      
      
    })
    
  }
  
  
  var desc=Product_Details.product_description;
  
  
  var bottom_content=Product_Details.description_bottom;

  var bottom_type = Product_Details.bottom_type;
  
   var top_type=Product_Details.top_type;
  
  var top_content=Product_Details.description_top;
  
  var top_header=Product_Details.description_top_header;
  
  var bottom_header=Product_Details.description_bottom_header;
  
 
  var discount=Product_Details.discount;


   const buy_func = ()=> {
    
 
     navigate('/checkout');
    
    
  };
  const diment_click = (di) => {  
    set_singlesize(di)
   
    getProduct_price(di)
  };
  

  const toggleCustomsize = () => {
    var element = document.getElementById("custom-size");
    
    if (element.hasAttribute("hidden")) {
      element.removeAttribute("hidden");
    } else {
      element.setAttribute("hidden", true);
    }
  };
  
  const toggleCouponButton = () => {
    var element = document.getElementById("coupon-section1");

    if (element.hasAttribute("hidden")) {
      element.removeAttribute("hidden");
    } else {
      element.setAttribute("hidden", true);
    }
  };

  
  
  
  
  const addTocart = (id, amt, title,image,bed_type,dimension,thickness) => {
    //TODO METHOD ACTION FOR ADD
    toast.success("product Added Successfully ");
    
    
    
    setCart(cartSection.addCart(id, amt, title,image,bed_type,singlesize,thickness,"None","Stock Color",Product_Details.discount,amt,0));
    
  }
  
  //Discouted Price Here 
  
  const discount_less=percentageCalculation(price,discount);
  
  
  
  const share =price-discount_less;
  
  
  // const default_discount=Default_discount(share,coupen_code);
  

  const wishlist_add = async (p_id,total_amount,product_name,product_image,product_size,product_dimen,product_height) => {
    
 

    if(userData===null)
    {

      toast.error("Please Login");

    }
    else
    {
      toast.success("product Added Successfully ");
    const requestBody = {
      
      product_id: p_id,
      total_amount:total_amount,
      product_name:product_name,
      product_image:product_image,
      product_size:product_size,

      product_dimen:product_dimen,
      product_height:product_height,
      user_id:userData.email

    };
    
    
    
    const result_data = await axios
    
    .post(baseurl + "user/wishlist_add/", requestBody)
    
    .then(function (response) {
     
      
      //TO SET PRODUCT BED TYPE 
 
    })

    
   
    
  }

  }
  




  
  
  


  
  return (
    
    
    <>
    <div id="product-detail">
    <div className="main-content">
    <div id="wrapper-site">
    <div id="content-wrapper">
    <div id="main">
    <div className="page-home">
    <nav className="breadcrumb-bg">
    <div className="container no-index">
    <div className="breadcrumb">
    <ol>
    <li>
    <a href="#">
    <span>Home</span>
    </a>
    </li>
    <li>
    <a href="#">
    <span>Accessories </span>
    </a>
    </li>
    <li>
    <a href="#">
    <span>Details </span>
    </a>
    </li>
    </ol>
    </div>
    </div>
    </nav>
    <div className="container">
    <div className="content">
    <div className="row">
    
    <div className="col-sm-12 col-lg-12 col-md-12">
    <div className="main-product-detail">
    {/* <h2>{Product_Details.accessories_name}</h2> */}
    <div className="product-single row">
    <div className="product-detail col-xs-12 col-md-5 col-sm-5">
    <div className="page-content" id="content">
    <div className="images-container">
    <div className="js-qv-mask mask tab-content border">
    <div
    id="item1"
    className="tab-pane fade active in show"
    >
    <img
    
    src={imgurl+image1}
    alt="img"
    />
    </div>
    <div id="item2" className="tab-pane fade">
    <img
    src={imgurl+image2}
    alt="img"
    />
    </div>
    <div id="item3" className="tab-pane fade">
    <img
   src={imgurl+image3}
    alt="img"
    />
    </div>
    <div id="item4" className="tab-pane fade">
    <img
    src={imgurl+image4}
    alt="img"
    />
    </div>
    <div
    className="layer hidden-sm-down"
    data-toggle="modal"
    data-target="#product-modal"
    >
    <i className="fa fa-expand"></i>
    </div>
    </div>
    <ul className="product-tab nav nav-tabs d-flex">
    <li className="active col">
    <a
    href="#item1"
    data-toggle="tab"
    aria-expanded="true"
    className="active show"
    >
    <img
    src={imgurl+image1}
    alt="img"
    />
    </a>
    </li>
    <li className="col">
    <a href="#item2" data-toggle="tab">
    <img
 src={imgurl+image2}
    alt="img"
    />
    </a>
    </li>
    <li className="col">
    <a href="#item3" data-toggle="tab">
    <img
  src={imgurl+image3}
    alt="img"
    />
    </a>
    </li>
    <li className="col">
    <a href="#item4" data-toggle="tab">
    <img
   src={imgurl+image4}
    alt="img"
    />
    </a>
    </li>
    </ul>
    <div
    className="modal fade"
    id="product-modal"
    role="dialog"
    >
    <div className="modal-dialog">
    <div className="modal-content">
    <div className="modal-header">
    <div className="modal-body">
    <div className="product-detail">
    <div>
    <div className="images-container">
    <div className="js-qv-mask mask tab-content">
    <div
    id="modal-item1"
    className="tab-pane fade active in show"
    >
    <img
   src={imgurl+image1}
    alt="img"
    />
    </div>
    <div
    id="modal-item2"
    className="tab-pane fade"
    >
    <img
   src={imgurl+image2}
    alt="img"
    />
    </div>
    <div
    id="modal-item3"
    className="tab-pane fade"
    >
    <img
   src={imgurl+image3}
    alt="img"
    />
    </div>
    <div
    id="modal-item4"
    className="tab-pane fade"
    >
    <img
   src={imgurl+image4}
    alt="img"
    />
    </div>
    </div>
    <ul className="product-tab nav nav-tabs">
    <li className="active">
    <a
    href="#modal-item1"
    data-toggle="tab"
    className=" active show"
    >
    <img
   src={imgurl+image1}
    alt="img"
    />
    </a>
    </li>
    <li>
    <a
    href="#modal-item2"
    data-toggle="tab"
    >
    <img
src={imgurl+image2}
    alt="img"
    />
    </a>
    </li>
    <li>
    <a
    href="#modal-item3"
    data-toggle="tab"
    >
    <img
  src={imgurl+image3}
    alt="img"
    />
    </a>
    </li>
    <li>
    <a
    href="#modal-item4"
    data-toggle="tab"
    >
    <img
   src={imgurl+image4}
    alt="img"
    />
    </a>
    </li>
    </ul>
    </div>
    </div>
    </div>
    </div>
    </div>
    </div>
    </div>
    </div>
    </div>
    </div>
    </div>
    <div className="product-info col-xs-12 col-md-7 col-sm-7">
    <div className="detail-description">
    <div className="price-del">
    {/* <span className="price">£150.00</span> */}
    {/* <span className="float-right">
    <span className="availb">Availability: </span>
    <span className="check">
    <i
    className="fa fa-check-square-o"
    aria-hidden="true"
    ></i>
    IN STOCK
    </span>
  </span> */}
  </div>
  {/* <p className="description">
  Proin gravida nibh vel velit auctor aliquet.
  Aenean lorem quis bibendum auctor, nisi elit
  consequat etiam non auctor.
</p> */}


      
<div className="has-borders mobile-responisve">
                                      <p className="product-title-style">
                                        {Product_Details.accessories_name}{" "}
                                      </p>
                                      
                                    </div>

                                    <div className="product-price-container mobile-responisve">
                                      <div>
                                        <div className="price-info">
                                          <div
                                            className="mrp-price"
                                            style={{ marginTop: 0 }}
                                          >
                                            <p className="mrp-rate">
                                              M.R.P
                                              <s style={{ marginLeft: 8 }}>
                                                <i
                                                  className="fa fa-inr"
                                                  aria-hidden="true"
                                                >
                                                  {" "}
                                                </i>{" "}
                                                {price}
                                              </s>
                                            </p>
                                            <p className="offer-percentage">
                                              {discount}% off
                                            </p>
                                          </div>
                                          <div className="bg-btn">
                                            <a href="#">
                                              <span>You Save</span>{" "}
                                              <i
                                                className="fa fa-inr"
                                                aria-hidden="true"
                                              ></i>{" "}
                                              {discount_less }
                                            </a>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="current-amt">
                                        <a href="#">
                                          <i
                                            className="fa fa-inr"
                                            aria-hidden="true"
                                          ></i>{" "}
                                          {share - coupenDis}
                                        </a>
                                        <p>( inclusive of all taxes )</p>
                                      </div>
                                    </div>


            
 
                  
                  
                  
                  
                                  <div className="has-border " style={{marginTop:25}}>
                                      <label>DIMENTION IN INCHES</label>
                                      <div
                                        style={{
                                          display: "flex",
                                          flexDirection: "row",
                                          marginTop: 17,
                                          marginBottom: 10,
                                        }}
                                      >
                                        <select
                                          onChange={(event) =>
                                            diment_click(event.target.value)
                                          }
                                          className="form-controll slectDimention"
                                        >
                                          {size.length > 0
                                            ? size.map(
                                                (element_size, index) => (
                                                  <option
                                                    key={index}
                                                    value={
                                                      element_size.size
                                                    }
                                                  >
                                                    {
                                                     element_size.size
                                                    }
                                                  </option>
                                                )
                                              )
                                            : ""}
                                        </select>
                                       
                                      </div>
                                 

                                      {/* <div className="size">
                        <span className="size">Thickness :</span>
                        <select>
                        
                        
                        { thickness.length > 0 ?   
                          thickness.map(
                            (element_thickens, index) => (
                              
                              <option value="">{element_thickens.thickness}</option>
                              )
                              ) : ""}
                              
                              
                              
                              </select>
                            </div> */}
                                    </div>
                  
         
                         
                            
                         


                          

                                    <div className="buy-section mobile-responisve">
                                      <a
                                      onClick={() => addTocart(id,share-coupenDis ,Product_Details.accessories_name,Product_Details.image1,0,0,0)}

                                        style={{ color: "white" }}
                                        className="add-cart"
                                      >
                                        ADD TO CART
                                      </a>
                                      <a
                                        style={{ color: "white" }}
                                        className="buy-now"
                                        onClick={() => {
                                        addTocart(id,share-coupenDis ,Product_Details.accessories_name,Product_Details.image1,0,0,0)
                                          buy_func();
                                        }}
                                      >
                                        BUY NOW
                                      </a>
                                    </div>





                                    <div className="pr-coupon desktop-responisve">
                                      <div
                                        style={{
                                          display: "flex",
                                          cursor: "pointer",
                                        }}
                                        onClick={() => toggleCouponButton()}
                                      >
                                        <img
                                          src={CoupenPng}
                                          alt={"Coupen"}
                                          width={20}
                                          height={20}
                                        />

                                        <label
                                          style={{
                                            cursor: "pointer",
                                            marginLeft: 10,
                                          }}
                                        >
                                          APPLY COUPON CODE
                                        </label>
                                      </div>
                                      <div
                                        id="coupon-section1"
                                        className="coupon-section"
                                        hidden
                                      >
                                        <textarea
                                          onChange={(event) =>
                                            set_newcoupen(event.target.value)
                                          }
                                        ></textarea>
                                        <a
                                          onClick={copenApply}
                                          style={{ color: "white" }}
                                        >
                                          Check
                                        </a>
                                      </div>
                                    </div>
                            

                                    <div className="product-price-container desktop-responisve" style={{marginTop:20}}>
                                      <div>
                                        <div className="price-info">
                                          <div
                                            className="mrp-price"
                                            style={{ marginTop: 0 }}
                                          >
                                            <p className="mrp-rate">
                                              M.R.P
                                              <s style={{ marginLeft: 8 }}>
                                                <i
                                                  className="fa fa-inr"
                                                  aria-hidden="true"
                                                >
                                                  {" "}
                                                </i>{" "}
                                                {price}
                                              </s>
                                            </p>
                                            <p className="offer-percentage">
                                              {discount}% off
                                            </p>
                                          </div>
                                          <div className="bg-btn">
                                            <a href="#">
                                              <span>You Save</span>{" "}
                                              <i
                                                className="fa fa-inr"
                                                aria-hidden="true"
                                              ></i>{" "}
                                              {discount_less }
                                            </a>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="current-amt" style={{paddingLeft:50}}>
                                        <a href="#">
                                          <i
                                            className="fa fa-inr"
                                            aria-hidden="true"
                                          ></i>{" "}
                                          {share - coupenDis}
                                        </a>
                                        <p>( inclusive of all taxes )</p>
                                      </div>
                                    </div>

                                    

                               


                                    <div className="product-quantity  desktop-responisve">
                                        <div className="qty">
                                          <div className="input-group input-group-cus">
                                            <span className="add">
                                              <button
                                               className="buynow"
                                             
                                                data-button-action="add-to-cart"
                                                type="submit"
                                                onClick={() =>
                                                  addTocart(id,share-coupenDis ,Product_Details.accessories_name,Product_Details.image1,0,0,0)

                                                }
                                              >
                                                <i
                                                  className="fa fa-shopping-cart"
                                                  aria-hidden="true"
                                                  style={{marginRight:10}}
                                                ></i>
                                                <span>Add to cart</span>
                                              </button>
                                         
                                              
                                            </span>
                                            <button
                                              className="buynow"
                                              style={{background:"black"}}
                                              onClick={() => {
                                                addTocart(id,share-coupenDis ,Product_Details.accessories_name,Product_Details.image1,0,0,0)
                                                buy_func();
                                              }}
                                            >
                                              Buy Now
                                            </button>
                                          </div>
                                        </div>
                                      </div>



                         
                         
                          </div>
                          </div>
                          </div>
                          
                          <div className="review">
                          <ul className="nav nav-tabs">
                          <li className="active">
                          <a
                          data-toggle="tab"
                          href="#description"
                          className="active show"
                          >
                          Description
                          </a>
                          </li>
                      
                        <li>
                        <a data-toggle="tab" href="#review">
                        Reviews ({ customer_review_list.length})
                        </a>
                        </li>
                        </ul>
                        
                        <div className="tab-content">
                        <div
                        id="description"
                        className="tab-pane fade in active show "
                                >
                                 <div className="desc"><img className="desc1" src={imgurl+description_image} /></div> 
                         
                      
                        <div dangerouslySetInnerHTML={{ __html: desc}} />
                        
                        </div>
                        
                        <div id="review" className="tab-pane fade">
                        <div className="spr-form">
                        <div className="user-comment">


                        { customer_review_list.length > 0 ?   
              customer_review_list.map(
                (element_com, index) => (


                        <div key={index} className="spr-review">
                        <div className="spr-review-header">
                        <span className="spr-review-header-byline">
                        <strong>{element_com.name.toUpperCase()}</strong> -
                        <span>{element_com.date_time}</span>
                        </span>
                   
                        </div>
                        <div className="spr-review-content">
                        <p className="spr-review-content-body">
                        {element_com.comment}
                        </p>
                        </div>
                        </div>

                )):"No Reviews Founded!"}

                        
                        </div>
                        </div>
                        <form
                       
                        
                        id="review-form"
                        >
                        <input
                        type="hidden"
                        name="review[rating]"
                        value="3"
                        />
                        <input type="hidden" name="product_id" />
                        <h3 className="spr-form-title">
                        Write a review
                        </h3>
                       
                        <fieldset className="spr-form-contact">
                        <div className="spr-form-contact-name">
                        <input
                        className="spr-form-input spr-form-input-text form-control"
                        
                        placeholder="Enter your name"
                        onChange={(event)=> set_name(event.target.value)}/>
                        </div>
                        <div className="spr-form-contact-email">
                        <input
                        className="spr-form-input "
                       
                        placeholder="Enter your email"
                        onChange={(event)=> set_email(event.target.value)}
                        />
                        </div>
                        </fieldset>
                        <fieldset>
                        <div className="spr-form-review-body">
                        <div className="spr-form-input">
                        <textarea
                        className="spr-form-input-textarea"
                        rows="10"
                        placeholder="Write your comments here"
                        onChange={(event)=> set_comments(event.target.value)}
                        ></textarea>
                        </div>
                        </div>
                        </fieldset>
                        <div className="submit">
                        <input
                        type="button"
                        name="addComment"
                        id="submitComment"
                        className="btn btn-default"
                        value="Submit Review"

                        onClick={() =>{ customer_review()
                        }}
                        />
                        </div>
                        </form>
                        </div>
                        <div id="tag" className="tab-pane fade in">
                        <p>
                        Lorem ipsum dolor sit amet, consectetur
                        adipisicing elit, sed do eiusmod tempor
                        incididunt ut labore et dolore magna
                        aliqua.Lorem ipsum dolor sit amet,
                        consectetur adipisicing elit, sed do eiusmod
                        tempor incididunt ut labore et dolore magna
                        aliqua.
                        </p>
                        <p>
                        Lorem ipsum dolor sit amet, consectetur
                        adipisicing elit, sed do eiusmod tempor
                        incididunt ut labore et dolore magna
                        aliqua.Lorem ipsum dolor sit amet,
                        consectetur adipisicing elit, sed do eiusmod
                        tempor incididunt ut labore et dolore magna
                        aliqua.
                        </p>
                        </div>
                        </div>
                        </div>
                        </div>
                        </div>
                        </div>
                        </div>
                        </div>
                        </div>
                        </div>
                        </div>
                        </div>
                        </div>
                        </div>
                        <div className="cus-bt-container">
                        <div className="row">
                        <div id="cus-img" className="col-lg-6 col-md-6 col-xs-12 bg-cus">
                        <div className="product-dt-bottom">
              {/* <img src={ imgurl+image1} /> */}

              {/* <video ref="vidRef" src={imgurl + video.mp4} type="video/mp4"></video> */}
              {/* <source src={imgurl + "video.mp4"} type="video/mp4" /> */}
              
              {/* <div dangerouslySetInnerHTML={{ __html: `
        <video
          loop
          muted
          autoplay
          playsinline
          src="${imgurl + "video.mp4"}"
         
        />,
      ` }}></div>
               */}
              

              
              
               <img src={imgurl+top_img} />


                        </div>
                        </div>
                        <div className="col-lg-6 col-md-6 col-xs-12 bg-cus">
                        <div className="product-dt-bottom-cnt">
                        <h3>{top_header}</h3>
                        <p>
                        {top_content}
                        </p>
                        </div>
                        </div>
                        </div>
                        <div className="row left-right">
                        <div className="col-lg-6 col-md-6 col-xs-12 bg-cus">
                        <div className="product-dt-bottom-cnt">
                        <h3>{bottom_header}</h3>
                        <p>
                        {bottom_content}
                        </p>
                        </div>
                        </div>
                        <div id="cus-img" className="col-lg-6 col-md-6 col-xs-12 bg-cus">
                        <div className="product-dt-bottom">
                        <img src={imgurl+bot_img} />
                        </div>
                        </div>
                        </div>
      </div>
{/* <Related_product category={1} /> */}
                        <Loader />
                        </>
                        );
                      }
                      