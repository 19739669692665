import React, { useEffect } from 'react'

export default function WhatsappSnippet() {
    useEffect(() => {
        // Create a script element
        const script = document.createElement('script');
        // Set the script's attributes
        script.src = 'https://d3mkw6s8thqya7.cloudfront.net/integration-plugin.js';
        script.type = 'text/javascript';
        script.id = 'aisensy-wa-widget';
        script.setAttribute('widget-id', 'V96uj5');

        // Append the script to the document body
        document.body.appendChild(script);

        // Clean up the script when the component unmounts
        return () => {
            document.body.removeChild(script);
        };
    }, []); // Empty dependency array ensures this effect runs only once on mount

    return null; // No need to render anything
};

