import React from "react";
import { useEffect } from "react"
import loaderImage from "../assets/img/loader.gif";
function Loader() {
  useEffect(() => {
    var element = document.getElementById("page-preloader");
    const timer = setTimeout(() => {
      element.style = "display:none";
    }, 1000);
    return () => clearTimeout(timer);
  }, []);
  return (
    <div id="page-preloader">
      <div class="page-loading">
        {/* <div class="dot"></div>
        <div class="dot"></div>
        <div class="dot"></div>
        <div class="dot"></div>
        <div class="dot"></div> */}
        <img width={100} height={100} src={loaderImage} alt="img" />
      </div>
    </div>
  );
}

export default Loader;
