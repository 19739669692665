import { Link } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { category_list } from "../data/API";

import MenuBar from "./menubar-right";
import Sidebar from "../Components/sidebar-desktop";
import SidebarMbl from "./sidebar-mobile";
import logo from "../assets/img/logo.png";
import sec_img from "../assets/img/footer/paymant.png";
import { toast } from 'react-toastify';
import SideMenu from "./UI/sideMenu";
import WhatsappSnippet from "./whatsapp-cdn";

export default function Footer() {
  toast.configure();
  const [category, setCategory] = useState([]);

  const [newsmail, setnewsmail] = useState("");
  useEffect(() => {
    category_list_fun();
  }, []);
  const category_list_fun = async () => {

    let response = await category_list();
    response && setCategory(response.data.data);

  };

  const emailValidation = async (mail) => {
    const regex = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
    if (!mail || regex.test(mail) === false) {
      // this.setState({
      //     error: "Email is not valid"
      // });
      return false;
    }
    return true;
  }




  const insert_news = async (e) => {


    if (newsmail != "") {


      const mailcheck = await emailValidation(newsmail);
      if (mailcheck) {
        const requestdata = {
          email: newsmail
        };
        toast.success("Thanks For Your Response!");
        document.getElementById('tex').value = "";
      }

      else {
        toast.error("Invalide Mail Address");
      }
    }
    else {
      toast.error("Mail Address Should Be there!");
    }
  };

  return (
    <React.Fragment>
      <footer className="footer-one">
        <div className="inner-footer">
          <div className="container">
            <div className="footer-top col-lg-12 col-xs-12">
              <div className="row">
                <div className="tiva-html col-lg-4 col-md-12 col-xs-12">
                  <div className="block">
                    <div className="block-content">
                      <p className="logo-footer">
                        <img src={logo} style={{ height: "50px" }} alt="img" />
                      </p>
                      <p className="content-logo" style={{ marginTop: 20 }}>
                        Buy Mattress Online - India's Best Mattress Brand.
                      </p>
                    </div>
                  </div>
                  <div className="block">
                    <div className="block-content">
                      <ul>


                        {category.map((items_cat, id) => (



                          <li key={id} >
                            <Link to={`/productlist/${items_cat.category_name.replace(
                              / /g,
                              "-"
                            )}`} >
                              {items_cat.category_name}

                            </Link>


                          </li>

                        ))}



                      </ul>
                    </div>
                  </div>
                  <div className="block">
                    <div className="block-content">
                      <p className="img-payment ">
                        <img loading="lazy" className="img-fluid" src={sec_img} alt="img" />
                      </p>
                    </div>
                  </div>
                </div>
                <div className="tiva-html col-lg-4 col-md-6">
                  <div className="block m-top">
                    <div className="title-block">Contact Us</div>
                    <div className="block-content">
                      <div className="contact-us">
                        <div className="title-content">
                          <i className="fa fa-home" aria-hidden="true"></i>
                          <span>Address :</span>
                        </div>
                        <div className="content-contact address-contact">
                          <p>
                            S.F.No. 283/1B, Sukkampatti (Manalmedu), Thalapatti
                            (P.O.), Manmangalam (Tk), Karur - 639003, Tamil
                            Nadu, India.
                          </p>
                        </div>
                      </div>
                      <div className="contact-us">
                        <div className="title-content">
                          <i className="fa fa-envelope" aria-hidden="true"></i>
                          <span>Email :</span>
                        </div>
                        <div className="content-contact mail-contact">
                          <p>support@poppyindia.com</p>
                        </div>
                      </div>
                      <div className="contact-us">
                        <div className="title-content">
                          <i className="fa fa-phone" aria-hidden="true"></i>
                          <span>Hotline :</span>
                        </div>
                        <div className="content-contact phone-contact">
                          <p>+91 90548 48481</p>
                        </div>
                      </div>
                      <div className="contact-us">
                        <div className="title-content">
                          <i className="fa fa-clock-o" aria-hidden="true"></i>
                          <span>Opening Hours :</span>
                        </div>
                        <div className="content-contact hours-contact">
                          <p>Monday - Saturday / 09.30 AM - 06.30 PM</p>
                          <span>(Except Holidays)</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="tiva-modules col-lg-4 col-md-6">
                  <div className="mb-2 tw-flex tw-flex-col gap-2">

                    <Link className="tw-my-2" to={`/warranty`} >
                      {'Warranty Registration or Claim'}
                    </Link>
                    <Link className="tw-my-2" to={`/privacy-policy`} >
                      {'Privacy Policy'}
                    </Link>
                  </div>



                  <div className="block m-top">
                    <div className="block-content">
                      <div className="title-block">Newsletter</div>
                      <div className="sub-title">
                        Sign up to our newsletter to get the latest articles,
                        lookbooks voucher codes direct to your inbox
                      </div>
                      <div className="block-newsletter">
                        {/* <form action="#" method="post"> */}
                        <div className="input-group">
                          <input
                            type="text"
                            className="form-control"
                            name="email"
                            id="tex"
                            placeholder="Enter Your Email"
                            onChange={(event) => setnewsmail(event.target.value)}
                          />
                          <span className="input-group-btn">
                            <button

                              className="effect-btn btn btn-secondary "
                              name="submitNewsletter"
                              type="button"
                              onClick={(e) => insert_news(e)}
                            >
                              <span>subscribe</span>
                            </button>
                          </span>
                        </div>

                        {/* </form> */}
                      </div>
                    </div>
                  </div>
                  <div className="block m-top1">
                    <div className="block-content">
                      <div className="social-content">
                        <div className="title-block">Follow us on</div>
                        <div id="social-block">
                          <div className="social">
                            <ul className="list-inline mb-0 justify-content-end">
                              <li className="list-inline-item mb-0">
                                <a href="https://m.facebook.com/poppymattressindia/" target="_blank">
                                  <i className="fa fa-facebook"></i>
                                </a>
                              </li>

                              <li className="list-inline-item mb-0">
                                <a href="https://www.instagram.com/poppymattress" target="_blank">
                                  <i className="fa fa-instagram"></i>
                                </a>
                              </li>

                              <li className="list-inline-item mb-0">
                                <a href="https://in.linkedin.com/company/poppymattress" target="_blank">
                                  <i className="fa fa-linkedin"></i>
                                </a>
                              </li>
                              <li className="list-inline-item mb-0">
                                <a href="https://www.youtube.com/@poppymattress" target="_blank">
                                  <i className="fa fa-youtube"></i>
                                </a>
                              </li>

                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* <div className="block m-top1">
                    <div className="block-content">
                      <div className="payment-content">
                        <div className="title-block "><a href="https://admin.poppyindia.com/a_assets/catelog/Catalog.pdf" target="_blank" className="download_pdf"><i className="fa fa-download" aria-hidden="true"></i> Download Poppy Mattress Catalogue</a></div>
                        
                      </div>
                    </div>
                  </div>  */}
                </div>
              </div>
        <p className="tw-text-center tw-pt-5 md:tw-pt-12 lg:tw-pt-12 xl:tw-pt-12 tw-pb-2">Copyright © Poppy Mattress Pvt ltd. All rights reserved</p>

            </div>
          </div>

        </div>

      </footer>

      <div className="back-to-top">
        <a href="#">
          <i className="fa fa-long-arrow-up"></i>
        </a>
      </div>
      <Sidebar />
      <SidebarMbl />
      <MenuBar />
      <SideMenu />
      <WhatsappSnippet />
    </React.Fragment>
  );
}
